import { Empty } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import PageFooter from '../componentParts/footer'
import PageLoading from '../componentParts/pageLoading'
import TopNav from '../componentParts/topNav'
import generalPageLoading from '../states/general'
import { getCareer, getCareerTop } from '../states/home'

function CareerPage() {
  const [pageLoading, setPageLoading] = useRecoilState(generalPageLoading)
  const data = useRecoilValueLoadable(getCareerTop)
  const data2 = useRecoilValueLoadable(getCareer)
  const careerTopData = data.state === "hasValue" ? data.contents : {}
  const careerData = data2.state === "hasValue" ? data2.contents : {}
  console.log(careerData)

  useEffect(() => {
    setPageLoading( (Array.isArray(careerData) && Array.isArray(careerTopData)) || (Object.values(careerData).length && Object.values(careerTopData).length) ? false : true)
  }, [careerData, careerTopData])


  let dateFormater = (theDate) => {
    const dateobj = new Date(theDate);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return dateobj.toLocaleDateString(undefined, options)
  }
  return (
    <div className='w-100'>
      {
        pageLoading ? (
          <PageLoading />
        ) : (
          <div className='w-100'>
            <TopNav />
              <div className='w-100'>
                <div className='aboutTop bg-orange pt-100p pb-5' style={{ backgroundImage: 'url(/grain.png)', backgroundBlendMode: "darken", backgroundRepeat: "no-repeat", justifyContent: "flex-end"}} >
                  <div className='w-100 text-center'>
                    <div className='text-white pt-5'>
                      <p className='fs-1 fw-bold text-white'>
                        Start Your Career at Wayapaychat
                      </p>
                      <span>Where our employees are at the heart of what we do.</span>
                    </div>
                    <div className='pt-5'>
                      <a href={"#jobs" || careerTopData?.link_url || ""} className='btn btn-light text-orange fw-bold'>See Available Positions</a>
                    </div>
                  </div>
                </div>
                {/* d-${!careerTopData.status && "none"} */}
                <div className={`w-100 my-5`}>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='w-100'>
                          <div className=''>
                            <p className='fs-1 fw-bold text-black'>{careerTopData?.title || "Why Work with Us?"}</p>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: (
                                careerTopData?.content || 
                                <span>
                                  Whether you’re an accomplished professional or a promising new talent, expect to be challenged by us, your teammates, and the diverse clients we serve, from rocket-fuelled start-ups to the world’s leading organisations.
                                  You’ll constantly be learning, and your ideas will be welcome in an atmosphere of collaboration and teamwork.
                                  Here we offer more than just a job; we offer a career.
                                  As a market leader in evolving payment solutions, we believe that intelligence takes its proper form in simplicity. Therefore, we follow a simple recruitment process, not only hiring good people but ambitious, goal-driven individuals.
                                  For all talent-related matters, contact us here
                                </span>
                                )
                              }
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='w-100 row'>
                          <div className='col-md-6 col-lg-6 my-3'>
                            <img src={careerTopData?.image} alt="waya" className='w-100' />
                          </div>
                          <div className='col-md-6 col-lg-6 my-3'>
                            <img src={careerTopData?.image2} alt="waya" className='w-100' />
                          </div>
                          <div className='col-md-6 col-lg-6 my-3'>
                            <img src={careerTopData?.image3} alt="waya" className='w-100' />
                          </div>
                          <div className='col-md-6 col-lg-6 my-3'>
                            <img src={careerTopData?.image4} alt="waya" className='w-100' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
      
                <div className='w-100 my-5'>
                  {
                    careerData?.mind?.map(e=> (
                      // d-${!e.status && "none"}
                      <div className={`container`} key={e.id}>
                        <div className='text-center'>
                          <p className='fs-2 fw-bold'>{e.title ||  "Vibrant, Brilliant Minds"}</p>
                        </div>
                        <div className='w-100'>
                          <div className='container'>
                            <img alt='waya' src={e.image || '/images/team.png'} className='img-fluid w-100' /> 
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>    
                
                <div className='w-100'>
                  <div className='py-5' style={{backgroundImage: 'url(/grain.png)'}}>
                    <div className='container'>
                      <div className='w-100 text-center'>
                        <p className='fs-3 fw-900p text-black'>Benefits and Perks</p>
                      </div>
                      <div className='row text-center'>
                        {
                          careerData?.benefit?.length ? (
                            careerData?.benefit.map((val)=> (
                              // d-${!val.status && "none"}
                              <div className={`col-md-6 col-lg-4 my-2`} key={val.id}>
                                <div className='bg-white rounded-15p py-4 px-md-5'>
                                  <img alt='waya' src={val.image || '/posfeat/dash.png'} className='img-fluid my-4' />
                                  <p className='fs-5 fw-bold'>{val.title || "Health Insurance"}</p>
                                  <span className="">{val.content || "Real-time data and insights on your Wayapaychat Dashboard to make informed business decisions."}</span>
                                </div>
                              </div>
                            ))
                          ) : ""
                        }
                      </div>
                    </div>
                  </div>
                </div>
      
                <div className='w-100' id="jobs">
                  <div className='py-5 bg-white'>
                    <div className='container'>
                      <div className=''>
                        <p className='fs-4 fw-bold'>Available Openings</p>
                        <div className='text-end'>
                          <a href='/blogs' className='btn btn-outline-dark'>View all</a>
                        </div>
                      </div>
                      <div className='row'>
                        {
                          careerData?.jobs?.length ? (
                            careerData?.jobs?.map(e=> (
                              // d-${!e.status && "none"}
                              <div className={`col-md-8`}>
                                <div className='px-lg-5 shadow p-3'>
                                  <span className='text-uppercase'>{dateFormater(e.created_at)} </span>
                                  <p className='fs-5 fw-bold text-black' dangerouslySetInnerHTML={{__html: e.title}} />
                                  <span dangerouslySetInnerHTML={{__html: e.description}} />
                                  <div className='mt-3'>
                                    <a className='btn btn-sm btn-outline-dark'>Read more</a>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className='text-center'>
                              <Empty />
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <PageFooter />
          </div>
        )
      }
    </div>
  )
}

export default CareerPage