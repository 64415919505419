import PageFooter from '../componentParts/footer'
import GenericJumbotron from '../componentParts/genericJumbotron'
import TopNav from '../componentParts/topNav'
import Fade from 'react-reveal/Fade';
// import Zoom from 'react-reveal/Zoom';
import '../styles/about.scss'
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { getAboutContent, getSettings } from '../states/home';
import { imageUrl } from '../services/axios';
import { Image } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import generalPageLoading from '../states/general';
import { useEffect } from 'react';
import PageLoading from '../componentParts/pageLoading';
import { getAllContacts } from '../states/contact';

function About() {
  const [pageLoading, setPageLoading] = useRecoilState(generalPageLoading)
  const data = useRecoilValueLoadable(getAboutContent)
  const dataS = useRecoilValueLoadable(getSettings)
  const dataC = useRecoilValueLoadable(getAllContacts)
  const contacts = dataC.state === "hasValue" && dataC.contents ? dataC.contents : []
  const settings = dataS.state === "hasValue" ? dataS.contents : {}
  const aboutContent = data.state === "hasValue" ? data.contents : []


  useEffect(() => {
    setPageLoading( Array.isArray(aboutContent) || Object.values(aboutContent).length ? false : true)
  }, [aboutContent])

  const addresses = [
    {
      id: 1,
      image_url: '/ion_home.png',
      title: "Head Office",
      description: "69 Seefeldstrasse, Zurich, Switzerland"
    },
    {
      id: 2,
      image_url: '/ion_home.png',
      title: "African Hub Office",
      description: "14 Yeye Olofin Street, Lekki Phase 1, Lagos, Nigeria."
    },
  ]

  return (
    <div className='w-100'>
      {
        pageLoading ? (
          <PageLoading />
        ) : (
          <div className='w-100'>
            <TopNav />
            <div className='w-100 pt-70p'>
              <Fade bottom>
                <div className='aboutTop pt-5' style={{ backgroundImage: 'url(/images/about-top.png)', }}>
                  <div className='text-center pt-5'>
                    <p className='fs-4 fw-bold text-black' dangerouslySetInnerHTML={{
                      __html: "Wayapaychat is on a mission to simplify the complexities of payments by <br /> providing our All-in-One Solution to help you grow."
                    }} 
                    />
                  </div>
                </div>
              </Fade>

              <div className='w-100'>
                <Fade bottom>
                  <div className='container'>
                    <div className='text-center'>
                      <p className='fs-3 fw-900p' dangerouslySetInnerHTML={{__html: "About Waya Group"}} />
                    </div>
                    {
                      (Object.values(aboutContent).length && Object.values(aboutContent?.settings).length) || (Object.values(settings).length)  ? (
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className=''>
                              <p className='text-center fs-5 fw-bold mt-5' dangerouslySetInnerHTML={{__html: aboutContent?.settings?.about_us || settings?.about_us || "Who We Are, What We Do"}} />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='w-100'>
                              <img alt='waya' src={aboutContent?.settings?.about_image || settings?.about_image || '/images/fallback.png'} className='w-100 img-fluid rounded-15p shadow-sm' />
                            </div>
                          </div>
                        </div>
                      ) : ""
                    }
                  </div>

                </Fade>
              </div>

              <div className='w-100 my-5'>
                <div className='w-100 bg-orange-faint'>
                  <Fade bottom>
                    <div className='container py-5'>
                      <div className='row'>
                        {
                          contacts?.map((e)=> (
                            <div key={e.id} className='col-md-6'>
                              <div className='text-center'>
                                <div>
                                  <img  width={50} height={50} alt='waya' src={e.image_url || '/ion_home.png'} />
                                  <p className='fs-5 fw-bold my-2' dangerouslySetInnerHTML={{__html: e.key }} />
                                  <span dangerouslySetInnerHTML={{__html: e.value}} />
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>

              <div className='w-100 my-5'>
                <Fade bottom>
                  <div className='w-100'>
                    <div className='text-center'>
                      <p className='fs-2 fw-900p' dangerouslySetInnerHTML={{__html: "Meet Our Executive Team" }} />
                    </div>
                    <div className='container'>
                      <div className='row'>
                        {
                          Object.values(aboutContent).length || (Array.isArray(aboutContent?.aboutContents) && aboutContent?.aboutContents?.length) ? (
                            aboutContent?.aboutContents?.map((val)=>(
                              <div className='col-md-4 my-3' key={val.id}>
                                <div className='text-center'>
                                  <div>
                                    <img alt='waya' src={val.image_url || '/images/fallback-sm.png'} className='rounded-15p shadow-sm img-fluid' />
                                    <h6 className='fs-5 fw-bold my-2' dangerouslySetInnerHTML={{__html: val.title}} />
                                    <p className='fs-5 fw-bold my-2' dangerouslySetInnerHTML={{__html: val.content}} />
                                      {/* {val.title}
                                    </p> */}
                                    {/* <span >
                                      {val.content}
                                    </span> */}
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : ""
                        }
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>

              <div className='w-100  my-5'>
                <Fade bottom>
                  <div className='w-100'>
                    {
                        Object.values(aboutContent).length || (Array.isArray(aboutContent?.aboutOthers) && aboutContent?.aboutOthers?.length) ? (
                          aboutContent?.aboutOthers?.map((e)=> (
                            // d-${e.status ? "block" : "none"}
                            <div key={e.id} className={`text-center container px-md-5`}>
                              <p className='fs-2 fw-900p lh-base' dangerouslySetInnerHTML={{__html: e.title}} />
                              <span dangerouslySetInnerHTML={{__html: e.content}} />
                              <div>
                                <Link to="/career" className='btn btn-lg btn-orange-dark-gradient  px-4 mt-4 rounded'>View Open Jobs <ArrowRightOutlined /></Link>
                              </div>
                            </div>
                          ))
                        ) : ""
                    }
                  </div>
                </Fade>
              </div>
            </div>
            <PageFooter />
          </div>
        )
      }

    </div>
  )
}

export default About