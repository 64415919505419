import '../styles/blog.scss'
import axios from 'axios'
import { useEffect, useState } from 'react'
import PageFooter from '../componentParts/footer'
import TopNav from '../componentParts/topNav'
import { useRouteMatch, Link, useParams, useLocation, useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { blogId, getBlogs } from '../states/blog'
import { fetching } from '../states/home'
import { imageUrl } from '../services/axios'
import { Empty, Image, Pagination } from 'antd'
import PageLoading from '../componentParts/pageLoading'
import generalPageLoading from '../states/general'
import { Fade } from 'react-reveal'
import { getSiteApi } from '../services/apiCalls'

function SingleBlogCategory() {
  let { id } = useParams();
  const [pageLoading, setPageLoading] = useRecoilState(generalPageLoading)
  const [blogs, setBlogs] = useState([])
  const history = useHistory()
  const location = useLocation()
  const {state} = location
  const { title, content} = state || {}

  
  const getBlogCategory = async () => {
      try {
        const result = await getSiteApi(`blogs/list/${id}`);
        setBlogs(result?.data?.data || [])
      } catch (error) {
        console.error(`ERROR: \n${error}`);
        return {};
      }
  };

  useEffect(() => {
    getBlogCategory()
  }, [])


  let dateFormater = (theDate) => {
    const dateobj = new Date(theDate);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return dateobj.toLocaleDateString(undefined, options)
  }

  useEffect(() => {
    setPageLoading( Array.isArray(blogs).length || Object.keys(blogs).length ? false : true)
  }, [blogs])

  const pagesize = 10
  const [from, setFrom] = useState(0)
  const blogData = [...blogs]
  const handlePagination = (value)=>{
      setFrom(pagesize*(value-1))
  }
  return (
    <div className='w-100'>
      <TopNav />
      <div id='blog' style={{ backgroundImage: 'url(/radial.png)' }} className="pt-60p">

        {
          pageLoading ? (
            <PageLoading />
          ) : (
            <div className='w-100'>
              {/* <div className='w-75' style={{margin:'0 auto'}}> */}
              <div className="blog-jumbotron w-100 bg-white pb-4 mb-4" >
                <div className="py-4 text-center">
                  <div className='pt-4 mt-4'>
                    <h1 className="w-font">{title || "Minimal blog template for creative expressions"}</h1>
                    <p className=''>{content || "100% customisable and SEO-friendly blog template for personal and commercial purposes."}</p>
                  </div>
                </div>
              </div>
              <Fade bottom>
              {/* onClick={()=> history.push({ pathname: `/blogs/${b.id}`, state: {title: b.title, content: b.short_content} })} */}
                <div className='container pb-4' style={{margin:'0 auto'}}>
                  {blogData?.splice(from, pagesize).map(el => (
                    <div className={`w-100 d-${el.status ? "block" : "none"}`}>
                      <Link onClick={()=> history.push({ pathname: `/blogs/${el.id}`, state: {title} })} key={el.id} className="text-dark">
                        <div className="bg-white p-3 rounded-15p shadow w-100 my-4 row minH-250p" key={el.id} >
                          <div className="col-md-8 h-100 position-relative">
                            <div className=''>
                              <span className=''>{dateFormater(el.updated_at)}</span>
                              {/* <p className='col-sm-4'>{`${el.read_time ? `${el.read_time} mins read` : 'few mins read'}`}</p> */}
                            </div>
                            <h5 className="fw-bold my-3 text-dark " dangerouslySetInnerHTML={{__html: el.title}} />
                            <span className="" dangerouslySetInnerHTML={{__html: el.short_content}} />
                            {/* <div className='w-100 h-100 overflow-auto'>
                              <span className="" dangerouslySetInnerHTML={{__html: el.content}} />
                            </div> */}
                          </div>
                          <div className='col-md-4 h-100'>
                            <div className='h-100'>
                              <img 
                                alt='waya blog'
                                src={el.image_url}
                                className="img-fluid h-100 w-100"
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                  }
                </div>
              </Fade>

              <div aria-label="Page  navigation example">
                <ul className="justify-content-center pagination">
                  <Pagination 
                    onChange={handlePagination} 
                    defaultCurrent={1} 
                    total={blogs?.length} 
                    pageSize={pagesize}
                  />
                </ul>
              </div>
            </div>
          )
        }
        <PageFooter />
      </div>
    </div>
  )
}

export default SingleBlogCategory