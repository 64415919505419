import PageFooter from '../componentParts/footer'
import TopNav from '../componentParts/topNav'
import Fade from 'react-reveal/Fade';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { getPrivacyPolicy, getSettings } from '../states/home';
import { useEffect } from 'react';
import generalPageLoading from '../states/general';
import PageLoading from '../componentParts/pageLoading';

function PrivacyPage() {
  const [pageLoading, setPageLoading] = useRecoilState(generalPageLoading)
  const data = useRecoilValueLoadable(getSettings)
  const privacy = useRecoilValueLoadable(getPrivacyPolicy)
  const settings = data.state === "hasValue" ? data.contents : {}
  const privacyPolicy = privacy.state === "hasValue" ? privacy.contents : []

  useEffect(() => {
    setPageLoading( (Array.isArray(privacyPolicy) && privacyPolicy.length) || Object.values(privacyPolicy).length ? false : true)
  }, [privacyPolicy])

  return (
    <div className='w-100'>
      {
        pageLoading ? (
          <PageLoading />
        ) : (
          <>
            <TopNav />
              <div className='w-100'>
                  <div className='aboutTop bg-orange pt-100p pb-5' style={{ backgroundImage: 'url(/grain.png)', backgroundBlendMode: "darken", backgroundRepeat: "no-repeat", justifyContent: "flex-end"}} >
                    <div className='w-100 text-center'>
                      <div className='text-white pt-5'>
                        <p className='fs-1 fw-bold text-white'>
                          Privacy Policy
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='w-100 my-5 py-5'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-7'>
                            {
                              Array.isArray(privacyPolicy) && privacyPolicy?.length ? (
                                privacyPolicy.map((tac)=> (
                                  <div className='w-100' key={tac.id} id={tac.title}>
                                    <div>
                                      <p className='fs-4 fw-bold'>
                                        {tac.title}
                                      </p>
                                      <p className='fs-6' dangerouslySetInnerHTML={{__html: tac.content}} />
                                    </div>
                                  </div>
                                ))
                              ) : ""
                            }
                          </div>

                          <div className='col-md-5'>
                            <div className='w-100 ps-lg-5'>
                              <div>
                                <p>
                                  On this  Page
                                </p>
                                {
                                  Array.isArray(privacyPolicy) && privacyPolicy.length ? (
                                    privacyPolicy.map((e)=> (
                                    <div key={e.id}>
                                      <a href={`#${e.title}`}>
                                        {e.title}
                                      </a>
                                    </div>
                                  ))
                                  ) : ""
                                }
                                  
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
              </div>
            <PageFooter />
          </>
        )
      }
    </div>
  )
}

export default PrivacyPage