import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, TwitterOutlined } from '@ant-design/icons';
import { Alert, Divider } from 'antd';
import { useState } from 'react';
import Fade from 'react-reveal/Zoom';
import { useRecoilValueLoadable } from 'recoil';
import { sendData } from '../services/apiCalls';
import { getSocialLinks } from '../states/contact';
import { getSettings } from '../states/home';
import '../styles/footer.scss'

function PageFooter() {
  const data = useRecoilValueLoadable(getSocialLinks)
  const social = data.state === "hasValue" ? data.contents : {}
  const settingsApi = useRecoilValueLoadable(getSettings)
  const settings = settingsApi.state === "hasValue" ? settingsApi.contents : {}
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({ show: false })

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await sendData({ data: { email }, url: "subscribe" })
    if (res.status) {
      setResponse({ status: "success", message: res.message, show: true })
      setEmail("")
    } else {
      setResponse({ status: "warning", message: res.message || res, show: true })
    }
    setLoading(false)
  }
  return (
    <footer className='w-100'>
      {response?.show &&
        // <div class={`alert alert-${response.status}`} role="alert">
        //   {response.message}
        // </div>
        <Alert showIcon closable type={response.status === "success" ? "success" : "error"} message={response.message || "an error occurred please try again!"} />
      }
      <Fade right cascade>
        <div className='top row pt-3' style={{ backgroundImage: 'url(/chat.png)' }}>
          <Fade right cascade>
            <div className='col mr-4 justify-content-between'>
              <h4 className='text-white'>Customer Support</h4>
              <p>If you have any questions or want to know more about Waya PayChat
                and our services, check our FAQ or contact our Customer Support</p>
              <div className='row'>
                <div className='col-12 col-md-3'>
                  <a href='/contact'>
                    <button className='orange'>Contact Us <span><img src='/rightArr.png' /></span></button>
                  </a>
                </div>
                <div className='col-12 col-md-9 ps-md-5' >
                  <div className='row'>
                    <div className='col-3 pl-md-5'>
                      <a href={social?.twitter} target='_blank' className='text-orange'>
                        <TwitterOutlined />
                      </a>
                    </div>
                    <div className='col-3 pl-md-5'>
                      <a href={social?.facebook} target='_blank' className='text-orange'>
                        <FacebookOutlined />
                      </a>
                    </div>
                    <div className='col-3 pl-md-5'>
                      <a href={social?.instagram} target='_blank' className='text-orange'>
                        <InstagramOutlined />
                      </a>
                    </div>
                    <div className='col-3  pl-md-5'>
                      <a href={social?.linked_in} target='_blank' className='text-orange'>
                        <LinkedinOutlined />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='inputs col pl-4 pt-4 mt-3 ml-4'>
              <p>Be the First to Know About our Promotions, Giveaways, and <br />Amazing Business Offers</p>
              <form onSubmit={handleSubmit}>
                <input value={email} onChange={(e) => setEmail(e.target.value)} type='text' placeholder='Enter your email address' />
                <button type='submit'>{loading ? "Loading..." : "Subscribe"}</button>
              </form>
            </div>
          </Fade>
        </div>
        <div className='bottom container pt-4'>
          {/* <hr className='pt-4 mt-4' /> */}
          <Divider type='horizontal' className='bg-secondary' />
          <div className='row justify-content-between'>
            <p className='col-md-5'>
              Copyright &copy; Waya {new Date().getFullYear()}  All rights reserved
            </p>
            <ul className='col-md-7'>
              <li>
                <a className="nav-link px-2" href="/faq">
                  FAQ
                </a>
              </li>
              <li>
                <a className="nav-link px-2" href="/about">
                  About Us
                </a>
              </li>
              <li>
                <a className="nav-link px-2" href="/terms-of-use">
                  Terms of use
                </a>
              </li>
              <li>
                <a className="nav-link px-2" href="/privacy-page">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a className="nav-link px-2" href="/contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className='row justify-content-between'>
            <p className='col-md-5'>
            </p>
            <ul className='col-md-7'>
              <li>
                <a className="nav-link px-2" href="https://wayabank.ng">
                  Wayabank
                </a>
              </li>
              <li>
                <a className="nav-link px-2" href="https://wayapos.ng">
                  Wayapos
                </a>
              </li>
              <li>
                <a className="nav-link px-2" href="https://wayagram.ng">
                  wayagram
                </a>
              </li>
              <li>
                <a className="nav-link px-2" href="https://wayaquick.com">
                  Wayaquick
                </a>
              </li>              
            </ul>
          </div>
        </div>
      </Fade>
    </footer>
  )
}

export default PageFooter