import '../styles/blog.scss'
import axios from 'axios'
import { useEffect, useState } from 'react'
import PageFooter from '../componentParts/footer'
import TopNav from '../componentParts/topNav'
import { useRouteMatch, Link } from "react-router-dom";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { blogId, getBlogs } from '../states/blog'
import { fetching } from '../states/home'
import { imageUrl } from '../services/axios'
import { Button, Empty, Image, Pagination } from 'antd'
import PageLoading from '../componentParts/pageLoading'
import generalPageLoading from '../states/general'
import { Fade } from 'react-reveal'
import { useHistory } from 'react-router-dom'

function Blog(props) {
  let { url } = useRouteMatch();
  // const setBlogId = useSetRecoilState(blogId)
  const data = useRecoilValueLoadable(getBlogs)
  const [pageLoading, setPageLoading] = useRecoilState(generalPageLoading)
  const blogs = data.state === "hasValue" && data.contents ? data.contents : {}
  const history = useHistory()

  const [BlogPageData, setBlogPageData] = useState({
    blog: [],
    category: [],
    top:{}
  })

  let dateFormater = (theDate) => {
    const dateobj = new Date(theDate);
    const options = { year: 'numeric', month: 'long', day: 'numeric' }; //  weekday: 'long',
    return dateobj.toLocaleDateString(undefined, options)
  }

  useEffect(() => {
    setBlogPageData({
      blog: blogs?.blog || [],
      category: blogs?.productCategories || {},
      top: blogs?.top || {}
    })
    setPageLoading( Array.isArray(data.contents).length || Object.keys(data.contents).length ? false : true)
  }, [data])
  

  const {
    blog,
    category,
    top,
  } = BlogPageData

  const blogsBackup=[
    {
      id: 1,
      image_url: '/blogPic.png',
      title: 'PAYMENT SOLUTION',
      short_content: 'Register, Login and Transact with your Phone number',
      updated_at: '2020-03-21'
    },
    {
      id: 2,
      image_url: '/blogPic.png',
      title: 'EASY TO USE',
      short_content: 'Add Bank card / setup Bank account and start transacting',
      updated_at: '2020-03-21'
    },
    {
      id: 3,
      image_url: '/blogPic.png',
      title: 'EASY TO TRANSACT',
      short_content: 'Link Your BVN, Top up your Wallet, Request for Payment, Accept payment, Transfer money, Pay for Subscriptions and Services, with just few clicks.',
      updated_at: '2020-03-21'
    },
    {
      id: 4,
      image_url: '/blogPic.png',
      title: 'PAYMENT OPTIONS',
      short_content: 'Transact, and Receive payments with or without Internet access with our multiple payment options.',
      updated_at: '2020-03-21'
    },
    {
      id: 5,
      image_url: '/blogPic.png',
      title: 'QR CODE',
      short_content: 'Scan and make payments, Send QR code to customers/contact list and get paid in minutes.',
      updated_at: '2020-03-21'
    },
    {
      id: 6,
      image_url: '/blogPic.png',
      title: 'CHAT, CALL, TRANSACT',
      short_content: 'Get in touch with your loved ones, send them pictures and videos, make free video and audio calls, and send them money',
      updated_at: '2020-03-21'
    },
    {
      id: 7,
      image_url: '/blogPic.png',
      title: 'SOCIALIZE',
      short_content: 'Meet new people, Post pictures and videos, create groups, advertise your products, follow people and synchronize with your other social media accounts',
      updated_at: '2020-03-21'
    },
  ]
  const pagesize = 10
    // const [from, setFrom] = useState(0)
    // const blogData = [...blog]
    // const handlePagination = (value)=>{
    //     setFrom(pagesize*(value-1))
    // }
    // console.log(blogData)

  return (
    <div className='w-100'>
      <TopNav />
      <div id='blog' style={{ backgroundImage: 'url(/radial.png)' }} className="pt-60p">
        <div className="w-100 bg-white pb-4 mb-4" >
          <div className="py-4 text-center">
            <div className='pt-4 mt-4'>
              <h1 className="d-none d-md-block w-font" dangerouslySetInnerHTML={{__html: top?.title}} />
              <h6 className="d-block d-md-none fw-4 w-font" dangerouslySetInnerHTML={{__html: top?.title}} />
              <p className='' dangerouslySetInnerHTML={{__html: top?.description}} />
            </div>
          </div>
        </div>

        {
          pageLoading ? (
            <PageLoading />
          ) : (
            <Fade bottom>
              <div className='container w-100 pb-4 w-100'>
                    {
                      blog.length ? (
                        blog.map((b)=> (
                          <div className={`container-lg d-${!b.status && "none"}`} style={{margin:'0 auto'}}>
                            <div className='float-start'>
                              <h5 className="my-3 text-dark" style={{fontWeight:'900', fontSize:'26px'}}>{b.title}</h5>
                            </div>
                            <div className='float-end'>
                              <button 
                                className='btn btn-light my-3 shadow'
                                onClick={()=> history.push({ pathname: `/blogs-category/${b.id}`, state: {title: b.title, content: b.short_content} })}>
                                <b>View all</b>
                              </button>
                            </div>
                            {b.blog.map((b)=>(
                              <Link onClick={()=> history.push({ pathname: `/blogs/${b.id}`, state: {title: b.title, content: b.short_content} })} key={b.id} className={`text-dark d-${!b.status && "none"}`}>
                                <div className="bg-white p-3 rounded-15p shadow w-100 my-4 row minH-250p" key={b.id} >
                                  <div className="col-md-8 h-100 position-relative">
                                    <div className='pt-2'>
                                      <span style={{color:"#667085", fontWeight: 400, fontSize: '13px', lineHeight: '120%' }} className='fw-bold'>
                                        {dateFormater(b.updated_at)}
                                      </span>
                                    </div>
                                    <div className='w-100 h-100 pt-3'>
                                      <h3 className="" dangerouslySetInnerHTML={{__html: b.title}} />
                                      <span dangerouslySetInnerHTML={{__html: b.short_content}} />
                                      {/* <span className="" dangerouslySetInnerHTML={{__html: b.description}} /> */}
                                    </div>
                                  </div>
                                  <div className='col-md-4 h-100'>
                                    <div className='h-100'>
                                      <img 
                                        alt='waya blog'
                                        src={b.image_url}
                                        className="img-fluid h-100 w-100"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            ))}
                          </div>
                        ))
                      ) : (
                        <div className='text-center'>
                          <Empty description="this category has no blog yet :)" />
                        </div>
                      )
                    }
              </div>
            </Fade>
          )
        }
        

        <div aria-label="Page  navigation example">
          <ul className="justify-content-center pagination">
            {/* <Pagination 
              // onChange={handlePagination} 
              // defaultCurrent={1} 
              // total={blog?.length} 
              // pageSize={pagesize}
              // itemRender={
              //   (page, type: 'page' | 'prev' | 'next', originalElement) => (
              //     <>
              //       {page}
              //       {originalElement}
              //       <li className="page-item"><a className="page-link" href="#">  <img src='/left.png' /></a></li>
              //       <li className="page-item active"><a className="page-link" href="#"><img src='/dot.png' /></a></li>
              //       <li className="page-item"><a className="page-link" href="#">  <img src='/right.png' /></a></li>
              //     </>
              //   )
              // }
            /> */}
          </ul>
        </div>
        <PageFooter />
      </div>
    </div>
  )
}

export default Blog