import PageFooter from '../componentParts/footer'
import TopNav from '../componentParts/topNav'
import Fade from 'react-reveal/Fade';
import '../styles/wayapay.scss'
import { ArrowRightOutlined } from '@ant-design/icons';
import getWayaPay from '../states/waya/wayaPay';
import { useState } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import generalPageLoading from '../states/general';
import { useEffect } from 'react';
import PageLoading from '../componentParts/pageLoading';
import { getSettings } from '../states/home';

function WayaPay() {

  const [WayaBankData, setWayaBankData] = useState({
    homeTopData: [],
    homeBenefitsData: [],
    homeBusinessData: [],
    homeDownloadData: [],
    homeIntegrateData: [],
    homeProductData: [],
    homeServicesData: [],
  })
  const data = useRecoilValueLoadable(getWayaPay)
  const [pageLoading, setPageLoading] = useRecoilState(generalPageLoading)
  const home = data.state === "hasValue" ? data.contents : {}
  const settingsApi = useRecoilValueLoadable(getSettings)
  const settings = settingsApi.state === "hasValue" ? settingsApi.contents : {}
  
  
  useEffect(() => {
    setWayaBankData({
      homeTopData: (home && home.bank) || [],
      homeBenefitsData: (home && home.benefit) || [],
      homeBusinessData: (home && home.business) || [],
      homeDownloadData: (home && home.download) || [],
      homeIntegrateData: (home && home.integrate) || [],
      homeProductData: (home && home.products) || [],
      homeServicesData: (home && home.services) || [],
    })
    setPageLoading( Array.isArray(home) || Object.keys(home).length ? false : true)
  }, [home])

  const {
    homeTopData,
    homeBenefitsData,
    homeBusinessData,
    homeProductData,
    homeServicesData,
    homeDownloadData,
    homeIntegrateData
  } = WayaBankData

  return (
    <div className='w-100'>
      {
        pageLoading ? (
          <PageLoading />
        ) : (
          <div className='w-100'>
            <TopNav />
            <div className="home-jumbotron pt-5 " style={{ backgroundImage: 'url(/wayapay.png)', }}>
              <div className='container-fluid w-100 pt-5 px-lg-5'>
                {
                  homeTopData?.map((e, index)=> (
                    (index+1) % 2 === 1 ? (
                      <div className={`row d-${!e.status && "none"}`}>
                        <div className='col-md-6'>
                          <Fade left className='w-100'>
                            <div className=''>
                              <h1 className="text-dark my-lg-5" dangerouslySetInnerHTML={{__html: e.title || "Accelerate your business with Wayapay payment Gateway"}} />
                              <p className='fs-4 fw-bold pe-md-5' dangerouslySetInnerHTML={{__html: e.description || "We have built a solution that allows you to collect payment from your customers online with very fast settlement and top-notch security."}} />
                              <div className='row py-4'>
                                <div className='col-md-4'>
                                  <a href={e.google_url || settings?.play_store_link || ""}><img alt='waya' src='/images/play-store.png' className='w-100' /></a>
                                </div>
                                <div className='col-md-4'>
                                  <a href={e.app_url || settings?.app_store_link || ""}><img alt='waya' src='/images/app-store.png' className='w-100' /></a>
                                </div>
                                <div className='col-md-4'>
                                  <a href={e.get_start_url || "/contact"} className='w-100 h-100 px-2 py-3 btn btn-orange bg-lg'><b>Get started</b></a>
                                  {/* <a href={e.get_start_url || ""} className='w-100 h-100 py-auto btn btn-orange bg-lg'>Get started</a> */}
                                </div>
                              </div>
                            </div>
                          </Fade>
                        </div>
                        <div className='col-md-6 w-100 pt-5'>
                          <Fade right>
                            <div className='' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "80%", backgroundPosition: "center" }}>
                              <div className='w-100 text-center'>
                                <img src={e.image_url || `/images/deskphone.png`} className="w-100 mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    ) : (
                      <div className={`row d-${!e.status && "none"}`}>
                        <div className='col-md-6 w-100 pt-5'>
                          <Fade right>
                            <div className='' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "80%", backgroundPosition: "center" }}>
                              <div className='w-100 text-center'>
                                <img src={e.image_url || `/images/deskphone.png`} className="w-100 mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                          </Fade>
                        </div>
                        <div className='col-md-6'>
                          <Fade left className='w-100'>
                            <div className=''>
                              <h1 className="text-dark my-lg-5" dangerouslySetInnerHTML={{__html: e.title || "Accelerate your business with Wayapay payment Gateway"}} />
                              <p className='fs-4 fw-bold pe-md-5' dangerouslySetInnerHTML={{__html: e.description || "We have built a solution that allows you to collect payment from your customers online with very fast settlement and top-notch security."}} />
                              <div className='row py-4'>
                                <div className='col-md-4'>
                                  <a href={e.google_url || settings?.play_store_link || ""}><img alt='waya' src='/images/play-store.png' className='w-100' /></a>
                                </div>
                                <div className='col-md-4'>
                                  <a href={e.app_url || settings?.app_store_link || ""}><img alt='waya' src='/images/app-store.png' className='w-100' /></a>
                                </div>
                                <div className='col-md-4'>
                                  <a href={e.get_start_url || "/contact"} className='w-100 h-100 px-2 py-3 btn btn-orange bg-lg'><b>Get started</b></a>
                                  {/* <a href={e.get_start_url || ""} className='w-100 h-100 py-auto btn btn-orange bg-lg'>Get started</a> */}
                                </div>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    )
                  ))
                }
              </div>
            </div>

            <div className='w-100'>
              <Fade bottom>
                <div className='w-100'>
                  {
                    homeBusinessData?.map((e, index)=> (
                      (index+1) % 2 === 1 ? (
                        <div className={`bg-orange-gradient w-100 py-5 d-${!e.status && "none"}`}>
                          <div className='row px-md-5'>
                            <div className='col-md-6 w-100'>
                              <div className='w-100 pt-3 text-center'>
                                <img src={e.image_url || `/images/map.png`} style={{width: "80%"}} className="mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                            <div className='col-md-6 py-5'>
                              <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "Accept Online Payments in Nigeria"}} />
                              <div className='pe-md-5 fs-6 text-black pt-5'>
                                <span dangerouslySetInnerHTML={{__html: e.description || "Reach a global market with a single integration to our secure payment platform flexible enough to suit your specific business needs. With Wayapay, you can collect online payments from shoppers in Nigeria and internationally while getting paid in NGN, USD, GBP, or any other currency of your choice."}} />
                              </div>
                              <a href={e.get_start_url || ""} className='btn btn-lg btn-orange-dark-gradient  px-4 mt-4 rounded'>Get Started Now! <ArrowRightOutlined /></a>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={`bg-orange-gradient w-100 py-5 d-${!e.status && "none"}`}>
                          <div className='row px-md-5'>
                            <div className='col-md-6 py-5'>
                              <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "Accept Online Payments in Nigeria"}} />
                              <div className='pe-md-5 fs-6 text-black pt-5'>
                                <span dangerouslySetInnerHTML={{__html: e.description || "Reach a global market with a single integration to our secure payment platform flexible enough to suit your specific business needs. With Wayapay, you can collect online payments from shoppers in Nigeria and internationally while getting paid in NGN, USD, GBP, or any other currency of your choice."}} />
                              </div>
                              <a href={e.get_start_url || ""} className='btn btn-lg btn-orange-dark-gradient  px-4 mt-4 rounded'>Get Started Now! <ArrowRightOutlined /></a>
                            </div>
                            <div className='col-md-6 w-100'>
                              <div className='w-100 pt-3 text-center'>
                                <img src={e.image_url || `/images/map.png`} style={{width: "80%"}} className="mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ))
                  }
                </div>
              </Fade>
            </div>


            <div className='w-100'>
              <Fade bottom>
                <div className='w-100'>
                  {
                    homeProductData?.map((e, index)=> (
                      (index+1) % 2 === 1 ? (
                        <div className={`'bg- w-100 py-5 d-${!e.status && "none"}`}>
                          <div className='row px-md-5'>
                            <div className='col-md-6 py-5'>
                              <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "Generate greater income. Receive more money conveniently"}} />
                              <div className='pe-md-5 fs-6 text-black pt-5'>
                                <span dangerouslySetInnerHTML={{__html: e.description || "Let your customers pay you easily at any time and from anywhere through the widest number of payment channels available today on a single application."}} />
                              </div>
                              <a href={e.get_start_url} className='btn btn-lg btn-orange-dark-gradient  px-4 mt-4 rounded'>Get Started Now! <ArrowRightOutlined /></a>
                            </div>
                            <div className='col-md-6 w-100'>
                              <div className='w-100 pt-3 text-center'>
                                <img src={e.image_url || `/images/deskphone.png`} style={{width: "80%"}} className="mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={`'bg- w-100 py-5 d-${!e.status && "none"}`}>
                          <div className='row px-md-5'>
                            <div className='col-md-6 w-100'>
                              <div className='w-100 pt-3 text-center'>
                                <img src={e.image_url || `/images/deskphone.png`} style={{width: "80%"}} className="mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                            <div className='col-md-6 py-5'>
                              <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "Generate greater income. Receive more money conveniently"}} />
                              <div className='pe-md-5 fs-6 text-black pt-5'>
                                <span dangerouslySetInnerHTML={{__html: e.description || "Let your customers pay you easily at any time and from anywhere through the widest number of payment channels available today on a single application."}} />
                              </div>
                              <a href={e.get_start_url} className='btn btn-lg btn-orange-dark-gradient  px-4 mt-4 rounded'>Get Started Now! <ArrowRightOutlined /></a>
                            </div>
                          </div>
                        </div>
                      )
                    ))
                  }
                </div>
              </Fade>
            </div>

            <div className='w-100'>
              <Fade bottom>
                <div className='w-100'>
                  {
                    homeServicesData?.map((e, index)=> (
                      (index+1) % 2 === 1 ? (
                        <div className={`bg-orange-gradient w-100 py-5 d-${!e.status && "none"}`}>
                          <div className='row px-md-5'>
                            <div className='col-md-6 w-100'>
                              <div className='w-100 pt-3 text-center'>
                                <img src={e.image_url || `/images/send.png`} style={{width: "80%"}} className="mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                            <div className='col-md-6 py-5'>
                              <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "Use Payment Links to Make Your Invoices Payable Online"}} />
                              <div className='pe-md-5 fs-6 text-black pt-5'>
                                <span dangerouslySetInnerHTML={{__html: e.description || "Quickly generate an invoice and a corresponding payment link from your Wayapay Dashboard. Send these to your customers via email, SMS or even WhatsApp as a request for payment."}} />
                              </div>
                              <a href={e.get_start_url} className='btn btn-lg btn-orange-dark-gradient  px-4 mt-4 rounded'>Get Started Now! <ArrowRightOutlined /></a>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={`bg-orange-gradient w-100 py-5 d-${!e.status && "none"}`}>
                          <div className='row px-md-5'>
                            <div className='col-md-6 py-5'>
                              <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "Use Payment Links to Make Your Invoices Payable Online"}} />
                              <div className='pe-md-5 fs-6 text-black pt-5'>
                                <span dangerouslySetInnerHTML={{__html: e.description || "Quickly generate an invoice and a corresponding payment link from your Wayapay Dashboard. Send these to your customers via email, SMS or even WhatsApp as a request for payment."}} />
                              </div>
                              <a href={e.get_start_url} className='btn btn-lg btn-orange-dark-gradient  px-4 mt-4 rounded'>Get Started Now! <ArrowRightOutlined /></a>
                            </div>
                            <div className='col-md-6 w-100'>
                              <div className='w-100 pt-3 text-center'>
                                <img src={e.image_url || `/images/send.png`} style={{width: "80%"}} className="mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ))
                  }
                </div>
              </Fade>
            </div>


            <div className='w-100'>
              <Fade bottom>
                <div className='w-100'>
                  {
                    homeBenefitsData?.map((e, index)=> (
                      (index+1) % 2 === 1 ? (
                        <div className={`bg- w-100 py-5 d-${!e.status && "none"}`}>
                          <div className='row px-md-5'>
                            <div className='col-md-6 w-100'>
                              <div className='w-100 pt-5 text-center'>
                                <img src={e.image_url || `/images/verified.png`} style={{width: "80%"}} className="mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                            <div className='col-md-6 py-5'>
                              <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "Enterprise-grade security"}} />
                              <div className='pe-md-5 fs-6 text-black pt-5'>
                                <span dangerouslySetInnerHTML={{__html: e.description || "We adhere to best-in-class security recommendations, providing solutions that align with global standards of utmost security."}} />
                              </div>
                              <div className='pe-lg-5 fs-6 pt-3'>
                                <ul style={{listStyleType: "none"}}>
                                  {
                                    e.listItem.map((val)=> (
                                      <li key={val.id} className="py-2"><img alt='waya' src='/images/check.png' className=''/> <span className='ps-md-4' dangerouslySetInnerHTML={{__html: val.title}} /></li>
                                    ))
                                  }
                                </ul>
                              </div>
                              <a href={e.get_start_url} className='btn btn-lg btn-orange-dark-gradient  px-4 mt-4 rounded'>Get Started Now! <ArrowRightOutlined /></a>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={`bg- w-100 py-5 d-${!e.status && "none"}`}>
                          <div className='row px-md-5'>
                            <div className='col-md-6 py-5'>
                              <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "Enterprise-grade security"}} />
                              <div className='pe-md-5 fs-6 text-black pt-5'>
                                <span dangerouslySetInnerHTML={{__html: e.description || "We adhere to best-in-class security recommendations, providing solutions that align with global standards of utmost security."}} />
                              </div>
                              <div className='pe-lg-5 fs-6 pt-3'>
                                <ul style={{listStyleType: "none"}}>
                                  {
                                    e.listItem.map((val)=> (
                                      <li key={val.id} className="py-2"><img alt='waya' src='/images/check.png' className=''/> <span className='ps-md-4' dangerouslySetInnerHTML={{__html: val.title}} /></li>
                                    ))
                                  }
                                </ul>
                              </div>
                              <a href={e.get_start_url} className='btn btn-lg btn-orange-dark-gradient  px-4 mt-4 rounded'>Get Started Now! <ArrowRightOutlined /></a>
                            </div>
                            <div className='col-md-6 w-100'>
                              <div className='w-100 pt-5 text-center'>
                                <img src={e.image_url || `/images/verified.png`} style={{width: "80%"}} className="mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ))
                  }
                </div>
              </Fade>
            </div>

            <div className='w-100'>
              <Fade bottom>
                <div className='w-100'>
                  {
                    homeIntegrateData?.map((e, index)=> (
                      <div className={`bg-orange-gradient w-100 py-5 d-${!e.status && "none"}`}>
                        <div className='w-100 px-md-5'>
                          <div className='text-center'>
                            <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "Easy Integration"}} />
                            <span className='fs-5 py-3 text-black' dangerouslySetInnerHTML={{__html: e.description || "Choose from an array of shopping carts or readily available APIs that <br /> will help your customers checkout."}} />
                          </div>
                          <div className='row my-5'>
                            {
                              e.listItem.map((e)=> (
                                <div className='col-12 col-sm-6 col-md-4 col-lg-3' key={e.id}>
                                  <div className='text-center'>
                                    <img alt='waya' src={e.image_url} className="w-50" style={{height: "120px"}} />
                                    <div className='py-3'>
                                      <button className='w-75 text-uppercase btn btn-lg btn-white bg-white shadow rounded-15p'>
                                        {e.title}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </Fade>
            </div>

            
            <div className='w-100'>
              <Fade bottom>
                <div className='w-00'>
                  {
                    homeDownloadData?.map((e, index)=> (
                      (index+1) % 2 === 1 ? (
                        <div className={`w-100 py-5 d-${!e.status && "none"}`}>
                          <div className='row px-md-5 pt-md-5'>
                            <div className='col-md-6 w-100' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "80%", backgroundPosition: "center" }}>
                              <div className='w-100 text-center'>
                                <img src={e.image_url || `/images/holding-phone2.png`} style={{width: "100%"}} className="w-100 mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                            <div className='col-md-6 mt-2 py-5'>
                              <p className='fs-70p fw-780p pe-lg-5 text-black lh-sm mb-3' dangerouslySetInnerHTML={{__html: e.title || "Download the mobile app"}} />
                              <div className='pe-lg-5 fs-6 text-black'>
                                <span dangerouslySetInnerHTML={{__html: e.description || "It only takes a few minutes to start enjoying free benefits. Download Wayabank on Google Play or the App Store."}} />
                              </div>
                              <div className='row justify-content-between pe-lg-5 py-4'>
                                <div className='col-md-6'>
                                  <a href={e.google_url || settings?.play_store_link || ""}><img alt='waya' src='/images/play-store.png' className='w-100' /></a>
                                </div>
                                <div className='col-md-6'>
                                  <a href={e.app_url || settings?.app_store_link || ""}><img alt='waya' src='/images/app-store.png' className='w-100' /></a>
                                </div>
                              </div>
                              <a href={e.get_start_url} className='btn btn-lg btn-orange-dark-gradient mx-3  px-4 mt-3 rounded'>Get Started Wayapay Now! <ArrowRightOutlined /></a>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={`w-100 py-5 d-${!e.status && "none"}`}>
                          <div className='row px-md-5 pt-md-5'>
                            <div className='col-md-6 py-5'>
                              <p className='fs-70p fw-780p pe-lg-5 text-black lh-sm mb-3' dangerouslySetInnerHTML={{__html: e.title || "Download the mobile app"}} />
                              <div className='pe-lg-5 fs-6 text-black'>
                                <span dangerouslySetInnerHTML={{__html: e.description || "It only takes a few minutes to start enjoying free benefits. Download Wayabank on Google Play or the App Store."}} />
                              </div>
                              <div className='row justify-content-between pe-lg-5 py-4'>
                                <div className='col-md-6'>
                                  <a href={e.google_url || settings?.play_store_link || ""}><img alt='waya' src='/images/play-store.png' className='w-100' /></a>
                                </div>
                                <div className='col-md-6'>
                                  <a href={e.app_url || settings?.app_store_link || ""}><img alt='waya' src='/images/app-store.png' className='w-100' /></a>
                                </div>
                              </div>
                              <a href={e.get_start_url} className='btn btn-lg btn-orange-dark-gradient  px-4 mt-4 rounded'>Get Started Now! <ArrowRightOutlined /></a>
                            </div>
                            <div className='col-md-6 w-100' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "80%", backgroundPosition: "center" }}>
                              <div className='w-100 text-center'>
                                <img src={e.image_url || `/images/holding-phone2.png`} style={{width: "100%"}} className="w-100 mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ))
                  }
                </div>
              </Fade>
            </div>
            <PageFooter />
          </div>
        )
      }
    </div>
  )
}

export default WayaPay
