import PageFooter from '../componentParts/footer'
import TopNav from '../componentParts/topNav'
import Fade from 'react-reveal/Fade';
import { getSettings, getTermsAndCondition } from '../states/home';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import generalPageLoading from '../states/general';
import { useEffect } from 'react';
import PageLoading from '../componentParts/pageLoading';

function TermsOfUse() {
  const data = useRecoilValueLoadable(getSettings)
  const tac = useRecoilValueLoadable(getTermsAndCondition)
  const [pageLoading, setPageLoading] = useRecoilState(generalPageLoading)

  
  const settings = data.state === "hasValue" ? data.contents : {}
  const termsAndCondition = tac.state === "hasValue" ? tac.contents : []

  useEffect(() => {
    setPageLoading( (Array.isArray(termsAndCondition) && termsAndCondition.length) || Object.values(termsAndCondition).length ? false : true)
  }, [termsAndCondition])

  return (
    <div className='w-100'>
      {
        pageLoading ? (
          <PageLoading />
        ) : (
          <>
            <TopNav />
            <div className='w-100'>
              <div className='aboutTop bg-orange pt-100p' style={{ backgroundImage: 'url(/grain.png)', backgroundBlendMode: "darken", backgroundRepeat: "no-repeat", justifyContent: "flex-end"}} >
                <div className='w-100'>
                  <div className='text-center text-white pt-5'>
                    <p className='fs-1 fw-bold text-white'>
                      Terms and Conditions
                    </p>
                  </div>
                </div>
              </div>

              <div className='w-100 my-5 py-5'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-7'>
                      {
                        Array.isArray(termsAndCondition) && termsAndCondition?.length ? (
                          termsAndCondition.map((tac)=> (
                            <div className='w-100' key={tac.id} id={tac.title}>
                              <div>
                                <p className='fs-4 fw-bold'>
                                  {tac.title}
                                </p>
                                <p className='fs-6' dangerouslySetInnerHTML={{__html: tac.content}} />
                              </div>
                            </div>
                          ))
                        ) : ""
                      }
                    </div>

                    <div className='col-md-5'>
                      <div className='w-100 ps-lg-5'>
                        <div>
                          <p>
                            On this  Page
                          </p>
                          {
                            Array.isArray(termsAndCondition) && termsAndCondition.length ? (
                              termsAndCondition.map((e)=> (
                              <div key={e.id}>
                                <a href={`#${e.title}`}>
                                  {e.title}
                                </a>
                              </div>
                            ))
                            ) : ""
                          }
                            
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PageFooter />
          </>
        )
      }
    </div>
  )
}

export default TermsOfUse