import PageFooter from '../componentParts/footer'
import GenericJumbotron from '../componentParts/genericJumbotron'
import TopNav from '../componentParts/topNav'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import '../styles/home.scss'
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { getHome, } from '../states/home';
import { imageUrl } from '../services/axios';
import { Button, Image, Spin, Switch } from 'antd';
import {useEffect, useState} from 'react'
import NewsCard from '../componentParts/newsCard';
import generalPageLoading from '../states/general';
import PageLoading from '../componentParts/pageLoading';
import { Link, useHistory } from 'react-router-dom';

function Home() {
  const [homePageData, setHomePageData] = useState({
    homeTopData: [],
    homeProductData: [],
    homePosData: [],
    homeProductListData: [],
  })
  const data = useRecoilValueLoadable(getHome)
  const [pageLoading, setPageLoading] = useRecoilState(generalPageLoading)
  const home = data?.state === "hasValue" ? data?.contents : {}
  const history = useHistory()

  
  useEffect(() => {
    setHomePageData({
      homeTopData: (home && home?.home) || [],
      homeProductData: (home && home?.homeProduct) || [],
      homePosData: (home && home?.homePOS) || [],
      homeProductListData: (home && home?.homeProductList) || [],
    })
    setPageLoading( Array.isArray(home) || Object.keys(home).length ? false : true)
  }, [home])

  const {
    homeTopData,
    homePosData,
    homeProductData,
    homeProductListData
  } = homePageData

  const homeNews = [
    {
      id: 1,
      img: '/images/news/n1.png',
      title: 'Fintech In Africa and how we are playing our roles',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus hac',
    },
    {
      id: 2,
      img: '/images/news/n2.png',
      title: 'Fintech In Africa and how we are playing our roles',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus hac',
    },
    {
      id: 3,
      img: '/images/news/n3.png',
      title: 'Fintech In Africa and how we are playing our roles',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus hac',
    },
    {
      id: 4,
      img: '/images/news/n4.png',
      title: 'Fintech In Africa and how we are playing our roles',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus hac',
    },
  ]
  return (
    <div className='w-100'>

      {
        pageLoading ? (
          <PageLoading />
          ) : (
          <div className='w-100' id='homesection'>
            <TopNav className='mb-4' />
            <div className="home-jumbotron pt-5 " style={{ backgroundImage: 'url(/home-bg.png)' }}>
              <div className='container-fluid w-100 pt-5 px-lg-5'>
                {
                  homeTopData?.map((e, index)=> (
                    (index+1) % 2 === 1 ? (
                      <div className={`d-${!e.status && "none"} row`} key={e.id}>
                        <div className='col-md-6 pt-100p'>
                          <Fade left className='w-100'>
                            <div className=''>
                              <h1 className="text-dark my-lg-5" dangerouslySetInnerHTML={{__html: e.title || "All in one ecosystem for banking, payment and Social lifestyle"}} />
                              <p className='fs-4 fw-bold pe-md-5' dangerouslySetInnerHTML={{__html: e.description || "One stop solution addressing the banking and underbanking needs, the digital payment space.​"}} />
                            </div>
                          </Fade>
                        </div>
                        <div className='col-md-6 text-center md-m'>
                          <Fade right>
                            <div className=''>
                              <img src={e.image_url || `/images/home1.png`} className="m-0 p-0 w-100 " alt='jumbotron' />
                            </div>
                          </Fade>
                        </div>
                      </div>
                    ) : (
                      <div className={`d-${!e.status && "none"} row`} key={e.id}>
                        <div className='col-md-6 text-center md-m'>
                          <Fade right>
                            <div className=''>
                              <img src={e.image_url || `/images/home1.png`} className="m-0 p-0 w-100 " alt='jumbotron' />
                            </div>
                          </Fade>
                        </div>
                        <div className='col-md-6 pt-100p'>
                          <Fade left className='w-100'>
                            <div className=''>
                              <h1 className="text-dark my-lg-5" dangerouslySetInnerHTML={{__html: e.title || "All in one ecosystem for banking, payment and Social lifestyle"}} />
                              <p className='fs-4 fw-bold pe-md-5' dangerouslySetInnerHTML={{__html: e.description || "One stop solution addressing the banking and underbanking needs, the digital payment space.​"}} />
                            </div>
                          </Fade>
                        </div>
                      </div>
                    )
                  ))
                }
              </div>
            </div>

            <div className='w-100'>
              <Fade bottom>
                <div className='py-4 w-100'>
                  {
                    homeProductData?.map(e=> (
                      <div className={`text-center py-3 d-${!e.status && "none"}`} key={e.id}>
                        <h3 className='fs-2 fw-900p m-0' dangerouslySetInnerHTML={{__html: e.title || "PRODUCT & SERVICES"}} />
                        <p className='lh-base pt-3 px-5 container-lg' dangerouslySetInnerHTML={{__html:
                            e.description || 
                            "One stop solution addressing the banking and underbanking needs, the digital payment space. Integrating banking and payment to our social lifestyle."
                          }}
                        />
                        <div style={{width: "5%"}} className='mx-auto rounded p-1 bg-orange' />
                      </div>
                    ))
                  }
                </div>
              </Fade>
            </div>

            <div className="w-100">
              {
                homeProductListData && homeProductListData.length ? (
                  homeProductListData.map((val, index)=> (
                    (index + 1) % 2 === 0 ? (
                      <div className={`w-100 d-${!val.status && "none"}`}>
                        <Fade bottom>
                          <div className='w-00'>
                            <div className='bg-orange-gradient w-100 py-5'>
                              <div className='row px-md-5 my-5 pt-md-5'>
                                <div className='col-md-6 w-100' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>
                                  <div className='w-100 pt-3 text-center'>
                                    <img src={val.image_url || `/images/deskphone.png`} style={{width: "100%"}} className="mx-auto" alt='jumbotron' />
                                  </div>
                                </div>
                                <div className='col-md-6 px-5 6 pt-80p'>
                                  <h1 className="text-dark my-lg-3" dangerouslySetInnerHTML={{__html: val.title || "Accept Payments Anytime, Anywhere"}} />
                                  <p className='fs-4 fw-bold pe-md-5' dangerouslySetInnerHTML={{__html: val.description || 
                                    "The simplest way to accept payments from customers anytime, anywhere via several methods including but not limited to; Credit/debit card, Bank Account, USSD, PayAttitude, Wallet etc."
                                    }}
                                  />
                                  <a className='btn btn-lg btn-orange rounded-pill px-4 mt-4' href={val.get_start_url || "/wayabank"}>Read More</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fade>
                      </div>
                    ) : (
                      <div className={`w-100 d-${!val.status && "none"}`}>
                        <Fade bottom>
                          <div className='w-100'>
                            <div className='bg-orange-gradient w-100 py-5'>
                              <div className='row px-md-5'>
                                {/* <div className='col-md-6 py-5'> */}
                                <div className='col-md-6 px-5 6 pt-40p'>
                                  <h1 className="text-dark my-lg-3" dangerouslySetInnerHTML={{__html: val.title || "Accept Payments Anytime, Anywhere"}} />
                                  <p className='fs-4 fw-bold pe-md-5' dangerouslySetInnerHTML={{__html: val.description || 
                                    "The simplest way to accept payments from customers anytime, anywhere via several methods including but not limited to; Credit/debit card, Bank Account, USSD, PayAttitude, Wallet etc."
                                    }}
                                  />
                                  {/* <h3 className='fs-2 fw-900p m-0' dangerouslySetInnerHTML={{__html: val.title || "Digital Banking"}} />
                                  <p className='lh-base pt-3 px-5 container-lg' dangerouslySetInnerHTML={{__html:
                                      val.description || 
                                      "Easy, Fast and Safe way to send and receive money. You can receive money into your accounts by transfer to just your phone number or email. You can also withdraw money from your wayabank account to your other banks. You can make bills payment such as; Airtime, Data, Utility, Betting etc."
                                    }}
                                  /> */}
                                  <a className='btn btn-lg btn-orange rounded-pill px-4 mt-4' href={val.get_start_url || "/wayapay"}>Read More</a>
                                </div>
                                <div className='col-md-6 w-100' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>
                                  <div className='w-100 pt-3 text-center'>
                                    <img src={val.image_url || `/images/two-screens.png`} style={{width: "90%"}} className="mx-auto" alt='jumbotron' />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fade>
                      </div>
                    )
                  ))
                ) : ""
              }
            </div>
            <div className={`w-100 d-${(homePosData?.every((val)=> !val.status)) && "none"}`}>
              <Fade bottom>
                <div className='container-fluid w-100 bg-orange-gradient'>
                  <div className='w-100'>
                    
                  {
                    homePosData && homePosData.length ? (
                      homePosData.map((val)=> (
                    <div className='row px-md-5 py-5'>
                      <div className='col-md-6 w-100' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>
                        <div className='w-100 pt-3 text-center'>
                          <img src={val.image_url || `/images/deskphone.png`} className="w-100 mx-auto" alt='jumbotron' />
                        </div>
                      </div>
                      {/* <div className='col-md-6'>
                        <div className='w-100'>
                          <div className='w-100 pt-3 text-center'>
                            <img 
                              src={(val.image_url) || ''} 
                              className="w-100 mx-auto" 
                              alt='jumbotron' 
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className='col-md-6 px-5 6 pt-80p'>
                        <h1 className="text-dark my-lg-3" dangerouslySetInnerHTML={{__html: val.title || "Accept Payments Anytime, Anywhere"}} />
                        <p className='fs-4 fw-bold pe-md-5' dangerouslySetInnerHTML={{__html: val.description || 
                          "The simplest way to accept payments from customers anytime, anywhere via several methods including but not limited to; Credit/debit card, Bank Account, USSD, PayAttitude, Wallet etc."
                          }}
                        />
                        <a className='btn btn-lg btn-orange rounded-pill px-4 mt-4' href={val.get_start_url || "/wayabank"}>Read More</a>
                      </div>
                      {/* <div className='col-md-6 py-2'>
                        <>
                          <div className='py-3'>
                            <h1 dangerouslySetInnerHTML={{ __html: val.title }} />
                            <p dangerouslySetInnerHTML={{ __html: val.description }} />
                            
                            <h1 dangerouslySetInnerHTML={{ __html: val.data?.title }} />
                            <p dangerouslySetInnerHTML={{ __html: val.data?.description }} />
                          </div>
                          <a href={val.get_start_url || "/wayagram"} className='btn btn-lg btn-orange rounded-pill px-4 mt-4'>Read More</a>
                        </>
                      </div> */}
                    </div>                    
                    ))
                    ) : ""
                  }
                  </div>
                </div>
              </Fade>
            </div>

            <div className='w-100'>
              <Fade bottom>
                <div className='w-100 py-5 container'>
                  <div className='my-3 text-center'>
                    {
                      home?.news?.length >= 1 && (
                        <p className='fs-2 fw-900p'>Latest News</p>
                      )
                    }
                  </div>
                  <div className='row'>
                    {
                      home?.news?.map((val)=> (
                        <div key={val.id} className={`col-md-3 my-2 minH-200p d-${!val.status && "none"}`}>
                          <Link onClick={()=> history.push({ pathname: `/blogs/${val.id}`, state: {title: val.title, content: val.short_content} })}>
                            <div className='rounded-5 shadow-sm h-100'>
                              <NewsCard
                                img={val.image_url}
                                title={val.title}
                                text={val.contents}
                              />
                            </div>
                        </Link>
                      </div>
                      ))
                    }
                  </div>
                </div>
              </Fade>
            </div>

            <div className='w-100'>
                <div className={`position-relative w-100 bg-orange-gradient py-5 d-${home?.connect?.length ? "block" : "none"}`}>
                  <div className='d-none d-md-block'>
                    <img alt="waya" src='/images/up.png' className='position-absolute top-0 start-0' />
                    <img alt="waya" src='/images/down.png' className='position-absolute bottom-0 end-0' />
                  </div>
                  <div>
                    {
                      home?.connect?.map((e)=> (
                        <div key={e.id} className={`d-${!e.status && "none"}`}>
                          <Zoom>
                            <div className="position-relative last-jumbotron pt-1 container my-5" style={{ backgroundImage: 'url(/grain.png)' }}>
                              <div className="w-100 pb-5">
                                <div className='text-center'>
                                  <p className="fs-1 fw-bold text-white mb-2" dangerouslySetInnerHTML={{__html: e.title || "Ready to connect with us?" }} />
                                  <p className='fs-5 py-2 text-white' dangerouslySetInnerHTML={{
                                    __html: e.content || e.description || "Let's explore available opportunities or <br /> create bespoke solutions to serve your business needs." }} 
                                  />
                                </div>
                                <div className='container'>
                                  <div className='row'>
                                    <div className='col-md-6 text-lg-end'>
                                      <a href={e.get_start_url || '/contact'} className='btn btn-lg btn-light w-75 px-md-5 py-3 rounded-pill text-orange'>Contact Us</a>
                                    </div>
                                    <div className='col-md-6 text-lg-start'>
                                      <a href={e.app_url  || e.image_url} download className='btn btn-lg btn-outline-light w-75 px-md-5 py-3 rounded-pill'>Download Brochure</a>
                                    </div>
                                  </div> 
                                </div>
                              </div>
                            </div>
                          </Zoom>
                        </div>
                      ))
                    }
                  </div>
                </div>
            </div>
            <PageFooter />
          </div>
        )
      }



    </div>
  )
}

export default Home