import '../styles/nav.scss'
import { useLocation, Link } from 'react-router-dom'
import { useRecoilValueLoadable } from 'recoil'
import { getSettingsApi } from '../states/contact'
// import { getCategories, getProducts } from '../states/product'
// import { useRecoilValueLoadable } from 'recoil'

function TopNav(params) {
  // const data = useRecoilValueLoadable(getProducts)
  // const dataC = useRecoilValueLoadable(getCategories)
  // const products = data.state === "hasValue" && data.contents ? data.contents.data : []
  // const categories = dataC.state === "hasValue" && dataC.contents ? dataC.contents.data : []
  // let location = useLocation() getSettingsApi
  
  const data = useRecoilValueLoadable(getSettingsApi)
  const logo = data.state === "hasValue" ? data.contents : {}
  return (
    <nav className="navbar navbar-expand-lg fixed-top" style={{ backgroundImage: 'url(/grain.png)' }}>
      <a className="navbar-brand ml-4 pl-3" href="/home">
        <img src={logo.logo_image ||'/Logo-white.png'} className="w-150p img-fluid" alt='wayachat logo' />
      </a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span className=""><img src={'/menu-icon.png'}></img> </span>
      </button>
      <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
        <ul className="navbar-nav">
          <li className="nav-item dropdown p-0">
            <a className="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Business
            </a>
            <ul className="dropdown-menu p-0 w-100" aria-labelledby="navbarScrollingDropdown">
              <li><Link className="dropdown-item w-100" to="/wayabank">Wayabank</Link></li>
              <li><hr className="dropdown-divider m-0 p-0" /></li>
              <li><Link className="dropdown-item w-100" to="/wayapay">Wayapay</Link></li>
              <li><hr className="dropdown-divider m-0 p-0" /></li>
              <li><Link className="dropdown-item w-100" to="/wayagram">Wayagram</Link></li>
              <li><hr className="dropdown-divider m-0 p-0" /></li>
              <li><Link className="dropdown-item w-100" to="/wayapos">Wayapos</Link></li>
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/how-it-works">How it works</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/blogs">Blog</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/about">About Us</a>
          </li>
          <li className={`nav-item link-btn`}>
            <a className="nav-link text-center" href="/contact">Contact Sales</a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default TopNav
