import { ArrowRightOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { Fade } from 'react-reveal'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import PageFooter from '../componentParts/footer'
import PageLoading from '../componentParts/pageLoading'
import TopNav from '../componentParts/topNav'
import generalPageLoading from '../states/general'
import { getSettings } from '../states/home'
import getWayaBank from '../states/waya/wayaBank'

function WayaBank() {

  const [featuresId, setFeaturesId] = useState(0)

  const [WayaBankData, setWayaBankData] = useState({
    homeTopData: [],
    homeBenefitsData: [],
    homeBusinessData: [],
    homeDownloadData: [],
    homeProductData: [],
    homeServicesData: [],
  })
  const data = useRecoilValueLoadable(getWayaBank)
  const [pageLoading, setPageLoading] = useRecoilState(generalPageLoading)
  const settingsApi = useRecoilValueLoadable(getSettings)
  const settings = settingsApi.state === "hasValue" ? settingsApi.contents : {}
  const home = data.state === "hasValue" ? data.contents : {}
  
  
  useEffect(() => {
    setWayaBankData({
      homeTopData: (home && home.bank) || [],
      homeBenefitsData: (home && home.benefit) || [],
      homeBusinessData: (home && home.business) || [],
      homeDownloadData: (home && home.download) || [],
      homeProductData: (home && home.products) || [],
      homeServicesData: (home && home.services) || [],
    })
    setFeaturesId(home?.products?.findIndex((n) => n.status))
    setPageLoading( Array.isArray(home) || Object.keys(home).length ? false : true)
  }, [home])

  const {
    homeTopData,
    homeBenefitsData,
    homeBusinessData,
    homeProductData,
    homeServicesData,
    homeDownloadData,
  } = WayaBankData



  const textList = [
    "Create instant bank account for personal and business​",
    "Self onboarding",
    "Pay bills online​",
    "Manage your bank accounts​",
    "Low transaction fee​",
    "Receive from on your wallet from everywhere",
    "Transfer to any bank and bank account"
  ]
  return (
    <div className='w-100'>
      {
        pageLoading ? 
        (
          <PageLoading />
        ) : (
          <div className='w-100'>
            <TopNav />
            <div className='w-100'>
              <div className='position-relative'>
                <img alt='waya' src='/images/half-ball.png' className='position-absolute img-fluid top-0 start-0' />
              </div>
              <div className="position-relative pt-5 ">
                <div className='container-fluid w-100 pt-5 px-lg-5'>
                  {
                    homeTopData?.map((e, index)=> (
                      (index+1) % 2 === 1 ? (
                        <div className={`row d-${!e.status && "none"}`}>
                          <div className='col-md-6 pt-100p'>
                            <Fade left className='w-100'>
                              <div>
                                <h1 className="text-black my-lg-3" dangerouslySetInnerHTML={{__html: e.title || "Digital Banking Solution for Personal & Business needs"}} />
                                <p className='fs-4 fw-bold pe-md-5' dangerouslySetInnerHTML={{__html: e.description || "The bank for a changing world.​"}} />
                                <div className='row justify-content-between pe-lg-5 py-4'>
                                  <div className='col-md-4 my-2'>
                                    <a href={e.app_url || e.app_url || ""}><img alt='waya' src='/images/play-store.png' className='w-100' /></a>
                                  </div>
                                  <div className='col-md-4 my-2'>
                                    <a href={e.google_url || e.google_url || ""}><img alt='waya' src='/images/app-store.png' className='w-100' /></a>
                                  </div>
                                  <div className='col-md-4 my-2'>
                                    {/* <a href={e.get_start_url || "/contact"} className='w-100 h-100 py-auto btn btn-orange bg-lg'>Get started</a> */}
                                    <a href={e.get_start_url || "/contact"} className='w-100 h-100 px-2 py-3 btn btn-orange bg-lg'><b>Get started</b></a>
                                  </div>
                                </div>
                              </div>
                            </Fade>
                          </div>
                          <div className='col-md-6 text-center pt-5'>
                            <Fade right>
                              <div className='w-100'>
                                <img src={e.image_url || `/images/wayabank1.png`} className="w-100 m-0 p-0 mx-auto " alt='jumbotron' />
                              </div>
                            </Fade>
                          </div>
                        </div>
                      ) : (
                        <div className={`row d-${!e.status && "none"}`}>
                          <div className='col-md-6 text-center pt-5'>
                            <Fade right>
                              <div className='w-100'>
                                <img src={e.image_url || `/images/wayabank1.png`} className="w-100 m-0 p-0 mx-auto " alt='jumbotron' />
                              </div>
                            </Fade>
                          </div>
                          <div className='col-md-6 pt-100p'>
                            <Fade left className='w-100'>
                              <div>
                                <h1 className="text-black my-lg-3" dangerouslySetInnerHTML={{__html: e.title || "Digital Banking Solution for Personal & Business needs"}} />
                                <p className='fs-4 fw-bold pe-md-5' dangerouslySetInnerHTML={{__html: e.description || "The bank for a changing world.​"}} />
                                <div className='row justify-content-between pe-lg-5 py-4'>
                                  <div className='col-md-4 my-2'>
                                    <a href={e.app_url || e.get_start_url || ""}><img alt='waya' src='/images/play-store.png' className='w-100' /></a>
                                  </div>
                                  <div className='col-md-4 my-2'>
                                    <a href={e.google_url || e.link2_url || ""}><img alt='waya' src='/images/app-store.png' className='w-100' /></a>
                                  </div>
                                  <div className='col-md-4 my-2'>
                                  <a href={e.get_start_url || "/contact"} className='w-100 h-100 px-2 py-3 btn btn-orange bg-lg'><b>Get started</b></a>
                                  {/* <a href={e.get_start_url || "/contact"} className='w-100 h-100 py-auto btn btn-orange bg-lg'>Get started</a> */}
                                  </div>
                                </div>
                              </div>
                            </Fade>
                          </div>
                        </div>
                      )
                    ))
                  }
                </div>
              </div>
            </div>
      
            <div className='w-100'>
              <Fade bottom>
                <div className='w-00'>
                  {
                    homeBusinessData?.map((e, index)=> (
                      (index+1) % 2 === 1 ? (
                        <div className={`w-100 py-5 d-${!e.status && "none"}`}>
                          <div className='row px-md-5 my-5 pt-md-5'>
                            <div className='col-md-6 w-100' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "80%", backgroundPosition: "center" }}>
                              <div className='w-100 pt-3 text-center'>
                                <img src={e.image_url || `/images/two-phone.png`} className="w-100 mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                            <div className='col-md-6 py-5'>
                              <p className='fs-2 fw-900p pe-lg-5' dangerouslySetInnerHTML={{__html: e.title || "A Fully Digital Banking app designed for Business & Personal need."}} />
                              <div className='pe-lg-5 fs-6'>
                                <ul style={{listStyleType: "none"}}>
                                  {
                                    e?.listItem?.map((val)=> (
                                      <li key={val.id} className="py-2"><img alt='waya' src='/images/check.png' className=''/> <span className='ps-md-4'>{val.title || val}</span></li>
                                    ))
                                  }
                                </ul>
                              </div>
                              <a href={e.get_start_url || ""} className='btn btn-lg btn-orange-dark-gradient  px-4 mt-4'>Open an account in a minute <ArrowRightOutlined /></a>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={`w-100 py-5 d-${!e.status && "none"}`}>
                          <div className='row px-md-5 my-5 pt-md-5'>
                            <div className='col-md-6 py-5'>
                              <p className='fs-2 fw-900p pe-lg-5' dangerouslySetInnerHTML={{__html: e.title || "A Fully Digital Banking app designed for Business & Personal need."}} />
                              <div className='pe-lg-5 fs-6'>
                                <ul style={{listStyleType: "none"}}>
                                  {
                                    e?.listItem?.map((val)=> (
                                      <li key={val.id} className="py-2"><img alt='waya' src='/images/check.png' className=''/> <span className='ps-md-4'>{val.title || val}</span></li>
                                    ))
                                  }
                                </ul>
                              </div>
                              <a href={e.get_start_url || ""} className='btn btn-lg btn-orange-dark-gradient  px-4 mt-4'>Open an account in a minute <ArrowRightOutlined /></a>
                            </div>
                            <div className='col-md-6 w-100' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "80%", backgroundPosition: "center" }}>
                              <div className='w-100 pt-3 text-center'>
                                <img src={e.image_url || `/images/two-phone.png`} className="w-100 mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ))
                  }
                </div>
              </Fade>
            </div>
      
            <div className='w-100'>
              <Fade bottom>
                <div className='w-100'>
                  <div className='container'>
                    <h1 className='text-black fs-1'>Products Features</h1>
                  </div>
                  <div className='w-100'>
                    <div className='w-100 bg-orange-gradient'>
                      <div className='w-100 container-fluid'>
                        <div className='row px-md-5'>
                          {
                            homeProductData.length && homeProductData.map((val, index)=> (
                              <button key={val.id} onClick={()=> setFeaturesId(index)} className={`d-${!val.status && "none"} col-6 col-sm-4 col-md-3 col-lg-2 text-center bg-transparent border-0`}>
                                <div className={`py-md-5 ${index === featuresId && "border-bottom border-5 border-danger"}`}>
                                  <img alt='waya' src={val.image_url} className="w-75 mx-auto mb-3" />
                                  <p className='m-0' dangerouslySetInnerHTML={{__html: val.title}} />
                                </div>
                                {/* <div style={{width: "100%"}} className={`mx-auto rounded p-1 bg-orange d-${index === featuresId ? "block" : "none"}`} /> */}
                              </button>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                    <div className='w-100 container py-4'>
                      <p className='text-black fw-bold fs-5' dangerouslySetInnerHTML={{__html: homeProductData[featuresId]?.sub_title || "Our account opening process is digital and self-service"}} />
                      <span dangerouslySetInnerHTML={{__html: homeProductData[featuresId]?.description || "Whether you are an Personal or a Business concern seeking the New Normal in Banking, We at Wayabank will handhold you to a world of Ease, Fast and Safe digital experience."}} />
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
      
            <div className='w-100'>
              {
                homeBenefitsData.length && homeBenefitsData.map((val, index)=> (
                  <>
                    <Fade bottom>
                    <div key={val.id} className={`w-100 ${(index+1) % 2 === 1 ? "bg-orange-gradient" : ""} d-${!val.status && "none"} p-md-5`}>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-md-5 pt-5'>
                            <p className='text-black fs-1 mb-3' dangerouslySetInnerHTML={{__html: val.title || "BENEFITS-"}} />
                            <p className='text-orange fs-1 fw-bold' dangerouslySetInnerHTML={{__html: val.description || "Merchants-"}} />
                          </div>
                          <div className='col-md-7'>
                            <div className='pe-lg-5 fs-6'>
                              <ul style={{listStyleType: "none"}}>
                                {
                                  val.listItem.map((tin)=> (
                                    <li key={tin.id} className="py-2 lh-sm d-flex">
                                      <p className='me-2'>
                                        <img alt='waya' src='/images/check.png' className=''/> 
                                      </p>
                                      <h5 className='ps-md-4' dangerouslySetInnerHTML={{__html: tin.title || "benefit"}} />
                                        {/* {tin.title} */}
                                        {/* <span className='ps-md-4  text-justify' dangerouslySetInnerHTML={{__html: tin.title}} /> */}
                                      {/* </h5> */}
                                    </li>
                                  ))
                                }
                              </ul>
                            </div>      
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
                    {/* <Fade bottom>
                      <div key={val.id} className={`w-100 ${(index+1) % 2 === 1 ? "bg-orange-gradient" : ""} d-${!val.status && "none"} p-5`}>
                        <div className='container'>
                          <div className='row'>
                            <div className='col-md-5 pt-5'>
                              <p className='text-black fs-1 mb-3' dangerouslySetInnerHTML={{__html: `${val.title || "BENEFITS"} -`}} />
                              <p className='text-orange fs-1 fw-bold' dangerouslySetInnerHTML={{__html: `${val.description || "Merchants"} :`}} />
                            </div>
                            <div className='col-md-7'>
                              <div className='pe-lg-5 fs-6'>
                                <ul style={{listStyleType: "none"}}>
                                  {
                                    val.listItem.map((tin)=> (
                                      <li key={tin.id} className="py-2"><img alt='waya' src='/images/check.png' className=''/> <span className='ps-md-4' dangerouslySetInnerHTML={{__html: tin.title}} /></li>
                                    ))
                                  }
                                </ul>
                              </div>      
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fade> */}
                  </>
                ))
              }
            </div>
      
            <div className='w-100'>
              <Fade bottom>
                <div className='w-00'>
                  <div className='w-100 py-5'>
                    {
                      homeServicesData?.map((e, index)=> (
                        (index+1) % 2 === 1 ? (
                          <div key={e.id} className={`row px-md-5 d-${!e.status && "none"}`}>
                            <div className='col-md-6 py-5 pt-md-5'>
                              <p className='fs-2 fw-900p pe-lg-5' dangerouslySetInnerHTML={{__html: e.title || "Empower your business with financial services"}} />
                              <div className='pe-lg-5 fs-6'>
                                <span dangerouslySetInnerHTML={{__html: e.description || "With the Wayabank Open APIs business users can embed services unto your platform and connect your customers to a wide range of banking services."}} />
                              </div>
                              <a href={e.get_start_url} className='btn btn-lg btn-orange-dark-gradient rounded-pill px-4 mt-4'>Read Our API Documentation</a>
                            </div>
                            <div className='col-md-6 w-100'>
                              <div className='w-100 pt-3 text-center'>
                                <img src={e.image_url || `/images/structure.png`} style={{width: "100%"}} className="w-100 mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div key={e.id} className={`row px-md-5 d-${!e.status && "none"}`}>
                            <div className='col-md-6 w-100'>
                              <div className='w-100 pt-3 text-center'>
                                <img src={e.image_url || `/images/structure.png`} style={{width: "100%"}} className="w-100 mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                            <div className='col-md-6 py-5 pt-md-5'>
                              <p className='fs-2 fw-900p pe-lg-5' dangerouslySetInnerHTML={{__html: e.title || "Empower your business with financial services"}} />
                              <div className='pe-lg-5 fs-6'>
                                <span dangerouslySetInnerHTML={{__html: e.description || "With the Wayabank Open APIs business users can embed services unto your platform and connect your customers to a wide range of banking services."}} />
                              </div>
                              <a href={e.get_start_url} className='btn btn-lg btn-orange-dark-gradient rounded-pill px-4 mt-4'>Read Our API Documentation</a>
                            </div>
                          </div>
                        )
                      ))
                    }
                  </div>
                </div>
              </Fade>
            </div>
      
      
            <div className='w-100'>
              <Fade bottom>
                <div className='w-00'>
                  <div className='w-100 py-5'>
                    {
                      homeDownloadData?.map((e, index)=> (
                        (index+1) % 2 === 1 ? (
                          <div className={`row px-md-5 pt-md-5 d-${!e.status && "none"}`}>
                            <div className='col-md-6 w-100' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "80%", backgroundPosition: "center" }}>
                              <div className='w-100 text-center'>
                                <img src={e.image_url || `/images/holding-phone.png`} style={{width: "90%"}} className="w-100 mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                            <div className='col-md-6 pt-100p'>
                              <p className='' dangerouslySetInnerHTML={{__html: e.title || ""}} />
                              <div className=''>
                                <span dangerouslySetInnerHTML={{__html: e.description || ""}} />
                              </div>
                              <div className='row justify-content-between pe-lg-5 py-4'>
                                <div className='col-md-6'>
                                  <a href={e.app_url || settings?.play_store_link}><img alt='waya' src='/images/play-store.png' className='w-100' /></a>
                                </div>
                                <div className='col-md-6'>
                                  <a href={e.google_url || settings?.app_store_link}><img alt='waya' src='/images/app-store.png' className='w-100' /></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className={`row px-md-5 pt-md-5 d-${!e.status && "none"}`}>
                            <div className='col-md-6 py-5 pt-100p'>
                              <p className='fs-70p fw-780p pe-lg-5 text-black lh-sm' dangerouslySetInnerHTML={{__html: e.title || "Download the mobile app"}} />
                              <div className='pe-lg-5 fs-6'>
                                <span dangerouslySetInnerHTML={{__html: e.description || "It only takes a few minutes to start enjoying free benefits. Download Wayabank on Google Play or the App Store."}} />
                              </div>
                              <div className='row justify-content-between pe-lg-5 py-4'>
                                <div className='col-md-6'>
                                  <a href={e.app_url || settings?.play_store_link}><img alt='waya' src='/images/play-store.png' className='w-100' /></a>
                                </div>
                                <div className='col-md-6'>
                                  <a href={e.google_url || settings?.app_store_link}><img alt='waya' src='/images/app-store.png' className='w-100' /></a>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 w-100' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "80%", backgroundPosition: "center" }}>
                              <div className='w-100 text-center'>
                                <img src={e.image_url || `/images/holding-phone.png`} style={{width: "90%"}} className="w-100 mx-auto" alt='jumbotron' />
                              </div>
                            </div>
                          </div>
                        )
                      ))
                    }
                  </div>
                </div>
              </Fade>
            </div>
            <PageFooter />
          </div>
        )
      }
    </div>
  )
}

export default WayaBank