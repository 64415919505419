import '../styles/howitworks.scss'
import { useEffect, useState } from 'react'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import PageFooter from '../componentParts/footer';
import TopNav from '../componentParts/topNav';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { getHome, getHowItWorks, getSettings } from '../states/home';
import { Collapse } from 'antd';
import { CaretRightOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import generalPageLoading from '../states/general';
import PageLoading from '../componentParts/pageLoading';

function HowItWorks() {

  const settingsApi = useRecoilValueLoadable(getSettings)
  const settings = settingsApi.state === "hasValue" ? settingsApi.contents : {}
  console.log(settings)

  const [WayaBankData, setWayaBankData] = useState({
    homeTopData: [],
    homeData: [],
    homeBenefitsData: [],
    homeBusinessData: [],
    homeDownloadData: [],
    homeIntegrateData: [],
    homeProductData: [],
    homeServicesData: [],
  })
  const data = useRecoilValueLoadable(getHowItWorks)
  const [pageLoading, setPageLoading] = useRecoilState(generalPageLoading)
  const home = data.state === "hasValue" ? data.contents : {}
  
  
  useEffect(() => {
    setWayaBankData({
      homeTopData: (home && home.bank) || [],
      homeData: (home && home) || [],
      homeBenefitsData: (home && home.benefit) || [],
      homeDownloadData: (home && home.download) || [],
      homeProductData: (home && home.products) || [],
    })
    setPageLoading( Array.isArray(home) || Object.keys(home).length ? false : true)
  }, [home])

  const {
    homeTopData,
    homeBenefitsData,
    homeProductData,
    homeDownloadData,
    homeData
  } = WayaBankData

  const collapse = [
    {
      id: 1,
      title: "Download Waya PayChat",
      text: "Enter your phone number, name, and password. You will get a One Time Password (OTP) (SMS code) on your phone. Enter the OTP to verify your phone number. Note that Waya PayChat only works with a Nigerian phone number. "
    },
    {
      id: 2,
      title: "Register",
      text: "Enter your phone number, name, and password. You will get a One Time Password (OTP) (SMS code) on your phone. Enter the OTP to verify your phone number. Note that Waya PayChat only works with a Nigerian phone number. "
    },
    {
      id: 3,
      title: "Login",
      text: "Enter your phone number, name, and password. You will get a One Time Password (OTP) (SMS code) on your phone. Enter the OTP to verify your phone number. Note that Waya PayChat only works with a Nigerian phone number. "
    },
    {
      id: 4,
      title: "Add Bank Card",
      text: "Enter your phone number, name, and password. You will get a One Time Password (OTP) (SMS code) on your phone. Enter the OTP to verify your phone number. Note that Waya PayChat only works with a Nigerian phone number. "
    },
    {
      id: 5,
      title: "Fund Your Wallet",
      text: "Enter your phone number, name, and password. You will get a One Time Password (OTP) (SMS code) on your phone. Enter the OTP to verify your phone number. Note that Waya PayChat only works with a Nigerian phone number. "
    },
    {
      id: 6,
      title: "Request Money",
      text: "Enter your phone number, name, and password. You will get a One Time Password (OTP) (SMS code) on your phone. Enter the OTP to verify your phone number. Note that Waya PayChat only works with a Nigerian phone number. "
    },
  ]
  return (
    <div>
      {
        pageLoading ? (
          <PageLoading />
        ) : (
          <div>
            <TopNav />
            <div id='howitworkssection'>
              <div className='pt-20p'>
                <Zoom>
                  <div className='videoSection'>
                    {
                      Object.values(settings).length && settings?.how_it_works_video_url ? (
                        <div className='w-75 mx-auto'>
                          <iframe
                            title="how it work" 
                            className='w-100'
                            style={{minHeight: "70vh"}}
                            src={settings?.how_it_works_video_url}
                          />
                        </div>
                      ) : (
                        <img alt="waya" src="/images/video.png" className='w-75 mx-auto img-fluid' />
                      )
                    }
                  </div>
                </Zoom>
              </div>

              <div className='w-100'>
                <div className='container'>
                <Collapse
                  // bordered={false}
                  accordion
                  defaultActiveKey={['1']}
                  expandIcon={({ isActive }) => <span className='text-white fw-bold fs-5'>{isActive ? <MinusOutlined color="white" /> : <PlusOutlined color='white'/>}</span>}
                  className="site-collapse-custom-collapse text-white bg-orange border-orange"
                  expandIconPosition="right"
                >
                  {
                    homeData?.map((val)=> (
                      <Collapse.Panel header={<b className={`text-white ps-md-5 fs-4`}>{val.title}</b>} key={val.id} className="site-collapse-custom-panel my-3 bg-orange p-0">
                        <div className='w-100 h-100 bg-white px-md-5'>
                          <p dangerouslySetInnerHTML={{__html: val.content}} />
                        </div>
                      </Collapse.Panel>
                    ))
                  }
                </Collapse>
                </div>
              </div>
              <PageFooter />
          </div>
        </div>
        )
      }
    </div>
  )
}

export default HowItWorks