import React, { useEffect } from 'react'
import PageFooter from '../../componentParts/footer'
import TopNav from '../../componentParts/topNav'
import Fade from 'react-reveal/Fade';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import generalPageLoading from '../../states/general';
import { getSettings } from '../../states/home';
import getWayaPos from '../../states/waya/wayaPos';
import PageLoading from '../../componentParts/pageLoading';

function WayaPos() {

    const data = useRecoilValueLoadable(getWayaPos)
    const [pageLoading, setPageLoading] = useRecoilState(generalPageLoading)
    const home = data.state === "hasValue" ? data.contents : {}
    const settingsApi = useRecoilValueLoadable(getSettings)
    const settings = settingsApi.state === "hasValue" ? settingsApi.contents : {}
    
    useEffect(() => {
        setPageLoading(Object.keys(home).length && Object.values(home).length ? false : true)
    }, [home])
    console.log(home)


    const posFeats = [
        {
            id: 1,
            img_url: "/images/wayapos/timer.png",
            title: "Instant Settlement"
        },
        {
            id: 2,
            img_url: "/images/wayapos/light.png",
            title: "Fast and Reliable Network "
        },
        {
            id: 3,
            img_url: "/images/wayapos/transfer-back.png",
            title: "Enjoy Lowest Charges "
        },
        {
            id: 4,
            img_url: "/images/wayapos/customer-care.png",
            title: "Great Customer Support "
        },
    ]

    const textList = [
        "Prints transaction receipts​",
        "Accepts transactions of any value",
        "Reduces costs and risks of cash handling​",
        "Accepts MasterCard, VISA and Verve cards​",
        "Generates reports for reconciliation purposes​",
        "Real time confirmation of transactions for merchants",
        "Send money to any Bank, Pay Bills, Buy Airtime"
    ]

    const posReasons = [
        {
            id: 1,
            img_url: "/images/wayapos/tag.png",
            title: "Trusted and Reliable Service",
            content: "Wayapos is a CBN licensed application platform. We offer low fees and a hassle-free way to carry out an array of services for your customers."
        },
        {
            id: 2,
            img_url: "/images/wayapos/swift-money.png",
            title: "Instant Transaction Settlement",
            content: "Receive funds into your settlement account instantly and offer transfer services to your customers in real-time. No waiting for end-of-day reconciliation."
        },
        {
            id: 3,
            img_url: "/images/wayapos/chart.png",
            title: "Business Intelligence and Growth Insights",
            content: "As your partner, your growth is our growth. We offer a powerful dashboard to view all your business transactions and helps you understand your business better."
        },
    ]

    return (
        <div className='w-100'>
            {
                pageLoading ? (
                    <PageLoading />
                ) : (
                    <div className='w-100'>
                        <TopNav/>
                        
                        <div className="position-relative pt-5 " style={{ backgroundImage: 'url(/grain.png)', }}>
                            {
                                home?.pos?.map((e, index)=> (
                                    (index+1) % 2 === 1 ? (
                                        <div key={e.id} className={`container-fluid w-100 pt-5 px-lg-5 d-${!e.status && "none"}`}>
                                            <div className="row py-md-5">
                                                <div className='col-md-6'>
                                                    <Fade left className='w-100'>
                                                        <h2 className="text-dark mt-5 my-lg-5 fs-1 fw-bold" dangerouslySetInnerHTML={{__html: e.title || "Waya Point of Sale (POS)"}} />
                                                        <p className='fs-5 fw-bold pe-md-5' dangerouslySetInnerHTML={{__html: e.description || "Your best partner for accepting payments from your customers and banking services on the go!"}} />
                                                        <div className='w-100 py-4'>
                                                            <div className='row'>
                                                                <div className='my-3 col-md-6'>
                                                                    <a href={e.get_start_url || ""} className='w-100 btn btn-orange fs-5 rounded-15p btn-lg py-3'>Register now</a>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='my-3 col-md-4'>
                                                                    <a href={e.google_url || ""}><img alt='waya' src='/images/play-store.png' className='w-100' /></a>
                                                                </div>
                                                                <div className='my-3 col-md-4'>
                                                                    <a href={e.app_url || ""}><img alt='waya' src='/images/app-store.png' className='w-100' /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                </div>
                                                <div className='col-md-6 w-100'>
                                                    <Fade right>
                                                        <div className='w-100 text-center'>
                                                            <img src={e.image_url || `/images/wayapos/pos-agent.png`} className="img-fluid w-75 mx-auto" alt='jumbotron' />
                                                        </div>
                                                    </Fade>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div key={e.id} className={`container-fluid w-100 pt-5 px-lg-5 d-${!e.status && "none"}`}>
                                            <div className="row py-md-5">
                                                <div className='col-md-6 w-100'>
                                                    <Fade right>
                                                        <div className='w-100 text-center'>
                                                            <img src={e.image_url || `/images/wayapos/pos-agent.png`} className="img-fluid w-75 mx-auto" alt='jumbotron' />
                                                        </div>
                                                    </Fade>
                                                </div>
                                                <div className='col-md-6'>
                                                    <Fade left className='w-100'>
                                                        <h2 className="text-dark mt-5 my-lg-5 fs-1 fw-bold" dangerouslySetInnerHTML={{__html: e.title || "Waya Point of Sale (POS)"}} />
                                                        <p className='fs-5 fw-bold pe-md-5' dangerouslySetInnerHTML={{__html: e.description || "Your best partner for accepting payments from your customers and banking services on the go!"}} />
                                                        <div className='row py-4'>
                                                        <div className='my-3 col-md-12'>
                                                            <a href={e.get_start_url || ""} className='w-50 btn btn-orange fs-5 rounded-15p btn-lg py-3'>Register now</a>
                                                        </div>
                                                        <div className='my-3 col-md-4'>
                                                            <img alt='waya' src='/images/play-store.png' className='w-100' />
                                                        </div>
                                                        <div className='my-3 col-md-4'>
                                                            <img alt='waya' src='/images/app-store.png' className='w-100' />
                                                        </div>
                                                        </div>
                                                    </Fade>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ))
                            }
                        </div>


                        <div className="w-100 bg-light">
                            <Fade bottom>
                                <div className='container py-4'>
                                    <div className='row py-4'>
                                        {
                                            home?.section2?.length && home?.section2?.map((e)=> (
                                                <div className={`col-12 col-md-3 my-2 d-${!e.status && "none"}`} key={e.id}>
                                                    <div className='w-100 shadow-sm rounded-15p text-center pb-3 bg-white'>    
                                                        <img alt='waya' src={e.image_url || ''} className="my-md-3 img-fluid" />
                                                        <p className='fw-bold m-0 p-0' dangerouslySetInnerHTML={{__html: e.title || ''}} />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </Fade>
                        </div>


                        <div className='w-100' style={{ backgroundImage: 'url(/grain.png)', }}>
                        <Fade bottom>
                            <div className='w-100'>
                                {
                                    home?.section3?.map((e, index)=> (
                                        (index+1) % 2 === 1 ? (
                                            <div className={`container w-100 py-4 d-${!e.status && "none"}`}>
                                                <div className='row px-md-5 my-4 pt-md-5'>
                                                <div className='col-md-6 w-100' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "80%", backgroundPosition: "center" }}>
                                                    <div className='w-100 pt-3 text-center'>
                                                    <img src={e.image_url || `/images/wayapos/pos.png`} className="w-100 mx-auto" alt='jumbotron' />
                                                    </div>
                                                </div>
                                                <div className='col-md-6 py-5'>
                                                    <p className='fs-2 fw-900p pe-lg-5' dangerouslySetInnerHTML={{__html: e.title || "Benefits of Wayapos"}} />
                                                    <div className='pe-lg-5 fs-6'>
                                                    <ul style={{listStyleType: "none"}}>
                                                        {
                                                        e?.posItem?.map((val)=> (
                                                            <li key={val.id} className={`py-2 d-${!val.status && "none"}`}><img alt='waya' src='/images/check.png' className=''/> <span className='ps-md-4' dangerouslySetInnerHTML={{__html: val.title}} /></li>
                                                        ))
                                                        }
                                                    </ul>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={`container w-100 py-5 d-${!e.status && "none"}`}>
                                                <div className='row px-md-5 my-5 pt-md-5'>
                                                <div className='col-md-6 py-5'>
                                                    <p className='fs-2 fw-900p pe-lg-5' dangerouslySetInnerHTML={{__html: e.title || "Benefits of Wayapos"}} />
                                                    <div className='pe-lg-5 fs-6'>
                                                    <ul style={{listStyleType: "none"}}>
                                                        {
                                                        e?.posItem?.map((val)=> (
                                                            <li key={val.id} className={`py-2 d-${!val.status && "none"}`}><img alt='waya' src='/images/check.png' className=''/> <span className='ps-md-4' dangerouslySetInnerHTML={{__html: val.title}} /></li>
                                                        ))
                                                        }
                                                    </ul>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 w-100' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "80%", backgroundPosition: "center" }}>
                                                    <div className='w-100 pt-3 text-center'>
                                                    <img src={e.image_url || `/images/wayapos/pos.png`} className="w-100 mx-auto" alt='jumbotron' />
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        )
                                    ))
                                }
                            </div>
                        </Fade>
                        </div>

                        <div className='w-100 bg-light'>
                            <Fade bottom>
                                <div className='w-100 py-5'>
                                    {
                                        home?.section4?.map((e)=> (
                                            <div className={`container py-5 d-${!e.status && "none"}`}>
                                                <div className='w-100'>
                                                    <h2 className='fw-bold fs-1 my-3' dangerouslySetInnerHTML={{
                                                        __html: e.title || "Here is why you should become a <br /> merchant with us"
                                                        }}
                                                    />
                                                </div>
                                                <div className='w-100'>
                                                    <div className='d-flex overflow-auto w-100 py-5'>
                                                        {
                                                        e?.posItem?.length ? (  e?.posItem?.map((val) => (
                                                            <div className={`d-inline minW-400p me-3 d-${!e.status && "none"}`} key={val.id}>
                                                                <div className='w-100 h-100 p-5 rounded-15p bg-white shadow-sm'>
                                                                    <div>
                                                                        <img alt='waya' src={val.image_url || ''} />
                                                                    </div>
                                                                    <div>
                                                                        <p className='fs-4 fw-bold' dangerouslySetInnerHTML={{__html: val.title || ''}} />
                                                                        <span dangerouslySetInnerHTML={{__html: val.description || ''}} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                        ) : ""
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Fade>
                        </div>



                        <div className='w-100'>
                            <Fade bottom>
                            <div className='w-100'>
                                {
                                    home?.section5?.map((e, index)=> (
                                        (index+1) % 2 === 1 ? (
                                            <div className={`container w-100 py-5 d-${!e.status && "none"}`}>
                                            <div className='row px-md-5 py-5'>
                                                <div className='col-md-6 py-5'>
                                                <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "One App to do everything!" }} />
                                                <div className='pe-md-5 fs-5 lh-lg text-secondary pt-5'>
                                                    <span dangerouslySetInnerHTML={{
                                                        __html: e.description || "Our one stop mobile application provides you with all the services that you can offer and more. Our new app is solely built with our merchants in mind and provides solutions to help you carry out financial services nationwide."
                                                        }}
                                                    >
                                                    </span>
                                                </div>
                                                </div>
                                                <div className='col-md-6 w-100'>
                                                <div className='w-100 pt-3 text-center'>
                                                    <img src={e.image_url || `/images/wayapos/phone.png`} className="w-75 img-fluid mx-auto" alt='jumbotron' />
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        ) : (
                                            <div className={`container w-100 py-5 d-${!e.status && "none"}`}>
                                            <div className='row px-md-5 py-5'>
                                                <div className='col-md-6 w-100'>
                                                <div className='w-100 pt-3 text-center'>
                                                    <img src={e.image_url || `/images/wayapos/phone.png`} className="w-75 img-fluid mx-auto" alt='jumbotron' />
                                                </div>
                                                </div>
                                                <div className='col-md-6 py-5'>
                                                <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "One App to do everything!" }} />
                                                <div className='pe-md-5 fs-5 lh-lg text-secondary pt-5'>
                                                    <span dangerouslySetInnerHTML={{
                                                        __html: e.description || "Our one stop mobile application provides you with all the services that you can offer and more. Our new app is solely built with our merchants in mind and provides solutions to help you carry out financial services nationwide."
                                                        }}
                                                    >
                                                    </span>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        )
                                    ))
                                }
                            </div>
                            </Fade>
                        </div>


                        <div className='w-100 bg-light'>
                            <Fade bottom>
                            <div className='w-100'>
                                {
                                    home?.others?.map((e, index)=> (
                                        (index+1) % 2 === 1 ? (
                                            <div className={`container w-100 py-5 d-${!e.status && "none"}`}>
                                            <div className='row px-md-5 py-5'>
                                                <div className='col-md-6 w-100'>
                                                <div className='w-100 pt-3 text-center'>
                                                    <img src={e.image_url || `/images/wayapos/cs-agent.png`} className="w-75 img-fluid mx-auto" alt='jumbotron' />
                                                </div>
                                                </div>
                                                <div className='col-md-6 py-5'>
                                                <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "24/7 SUPPORT"}} />
                                                <div className='pe-md-5 fs-5 lh-lg text-secondary pt-5'>
                                                    <span dangerouslySetInnerHTML={{
                                                        __html: e.description || "We understand the importance of support to your business and the efficiency it brings to your business, which is why we have profiled and trained customer support representatives available 24/7 for every transaction issue you experience." 
                                                        }}
                                                    />
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        ) : (
                                            <div className={`container w-100 py-5 d-${!e.status && "none"}`}>
                                            <div className='row px-md-5 py-5'>
                                                <div className='col-md-6 py-5'>
                                                <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "24/7 SUPPORT"}} />
                                                <div className='pe-md-5 fs-5 lh-lg text-secondary pt-5'>
                                                    <span dangerouslySetInnerHTML={{
                                                        __html: e.description || "We understand the importance of support to your business and the efficiency it brings to your business, which is why we have profiled and trained customer support representatives available 24/7 for every transaction issue you experience." 
                                                        }}
                                                    />
                                                </div>
                                                </div>
                                                <div className='col-md-6 w-100'>
                                                <div className='w-100 pt-3 text-center'>
                                                    <img src={e.image_url || `/images/wayapos/cs-agent.png`} className="w-75 img-fluid mx-auto" alt='jumbotron' />
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        )
                                    ))
                                }
                            </div>
                            </Fade>
                        </div>
                        <PageFooter/>
                    </div>
                )
            }
        </div>
    )
}

export default WayaPos
