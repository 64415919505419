import '../styles/single-post.scss'
import axios from 'axios'
import { useState, useEffect } from 'react'
import PageFooter from '../componentParts/footer'
import TopNav from '../componentParts/topNav'
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { imageUrl } from '../services/axios'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { fetching } from '../states/home'
import { blogId, getRecom, getSingleBlog } from '../states/blog'
import PageLoading from '../componentParts/pageLoading'
import { Fade } from 'react-reveal'
import { Card, Empty } from 'antd'

function SinglePost(props) {
  let { topicId } = useParams();
  const [blogIndex, setBlogId] = useRecoilState(blogId)
  setBlogId(topicId)
  // const [loading, setloading] = useRecoilState(fetching)
  const [loading, setloading] = useState(true)
  const data = useRecoilValueLoadable(getSingleBlog)
  const recom = useRecoilValueLoadable(getRecom)
  const singleData = data && data.state === "hasValue" && data.contents ? data.contents : {}
  const recommend = recom && recom.state === "hasValue" && recom.contents ? recom.contents : {}
  console.log(singleData)
  console.log(recommend)
  const history = useHistory()
  const location = useLocation()
  const {state} = location
  const { title} = state || {}

  let dateFormater = (theDate) => {
    const dateobj = new Date(theDate);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return dateobj.toLocaleDateString(undefined, options)
  }

  console.log(singleData)

  useEffect(() => {
    setloading(Array.isArray(data.contents) || Object.keys(data.contents).length ? false : true)
  }, [singleData])
  return (
    <div className='w-100 bg-light'>
      <TopNav />
      <div id='blog' style={{ backgroundImage: 'url(/radial.png)' }} className="pt-60p  bg-light ">
        {
          loading ? (
            <PageLoading />
          ) : (
            <>
              <div id='singlePost pt-100p' className='bg-white'>
                <div className="blog-jumbotron w-100 pb-4 mb-4" >
                  <div className="py-4 text-center">
                    <div className='pt-4 mt-4'>
                      <h1 className="w-font">{singleData.title || "Minimal blog template for creative expressions"}</h1>
                      {/* <p>{dateFormater(singleData.created_at)}</p> */}
                      <div className='pt-3 pb-5'>
                        <span style={{color:"#667085", fontWeight: 400, fontSize: '13px', lineHeight: '120%' }} className='fw-bold'>
                          {dateFormater(singleData.updated_at)} .&#160;
                        </span>
                        <strong style={{color:"black", fontWeight: 400, fontSize: '13px', lineHeight: '120%' }} className='fw-bold'>
                          {title}
                        </strong>
                      </div>
                      {/* <p className=''>{content || "100% customisable and SEO-friendly blog template for personal and commercial purposes."}</p> */}
                      <img className="img-fluid" alt='' src={singleData.image_url} />
                    </div>
                  </div>
                </div>
                <Fade bottom>
                  <div className='container pb-4 px-lg-5' style={{margin:'0 auto'}}>
                      <div className={`w-100  px-lg-5`}>
                        <div className="py-3 px-lg-5 w-100 my-4" >
                          <section>
                            <p dangerouslySetInnerHTML={{ __html: singleData.content }} />
                          </section>
                        </div>
                      </div>
                  </div>
                </Fade>
              </div> 
                            
              <Fade bottom>
                <div className='container px-lg-5 py-5' style={{margin:'0 auto'}}>
                  <div className=''>
                    <h5 className="my-3 text-dark" style={{fontWeight:'900', fontSize:'26px'}}>Recommended articles</h5>
                  </div>
                  { 
                    recommend.length ? recommend?.map(el => (
                      el.id !== singleData.id &&
                    <div className={`w-100 d-${el.status ? "block" : "none"}`}>
                      <button onClick={()=> history.push({ pathname: `/blogs/${el.id}`, state: {title} })} key={el.id} className="w-100 text-start text-dark bg-transparent border-0">
                        <div className="bg-white p-3 rounded-15p shadow w-100 my-4 row minH-250p" key={el.id} >
                          <div className="col-md-8 h-100 position-relative">
                            <div className=''>
                              <span className=''>{dateFormater(el.updated_at)}</span>
                            </div>
                            <h5 className="fw-bold my-3 text-dark " dangerouslySetInnerHTML={{__html: el.title}} />
                            <span className="" dangerouslySetInnerHTML={{__html: el.short_content}} />
                          </div>
                          <div className='col-md-4 h-100'>
                            <div className='h-100'>
                              <img 
                                alt='waya blog'
                                src={el.image_url}
                                className="img-fluid h-100 w-100"
                              />
                            </div>
                          </div>
                        </div>
                      </button>
                    </div>
                  )) : <div className='text-center'>
                    <Empty />
                  </div>
                  }
                </div>
              </Fade>
            </>
            
          )
        }
      </div>
      <PageFooter />
    </div>
  )
}

export default SinglePost