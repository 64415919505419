import { selector } from "recoil";
import { getSiteApi } from "../../services/apiCalls";

const getWayaPos = selector({
  key: "getWayaPos",
  get: async ({ get }) => {
    try {
      const result = await getSiteApi("waya_pos");
      return result.data || {};
    } catch (error) {
      console.error(`ERROR: \n${error}`);
      return {};
    }
  }
});

export default getWayaPos