import { selector } from "recoil";
import { getSiteApi } from "../../services/apiCalls";

const getWayaBank = selector({
  key: "getWayaBank",
  get: async ({ get }) => {
    try {
      const result = await getSiteApi("waya_bank");
      return result.data || {};
    } catch (error) {
      console.error(`ERROR: \n${error}`);
      return {};
    }
  }
});

export default getWayaBank