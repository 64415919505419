import { selector } from "recoil";
import { getSiteApi } from "../../services/apiCalls";

const getWayaGram = selector({
  key: "getWayaGram",
  get: async ({ get }) => {
    try {
      const result = await getSiteApi("waya_gram");
      return result.data || {};
    } catch (error) {
      console.error(`ERROR: \n${error}`);
      return {};
    }
  }
});

export default getWayaGram