import '../styles/wayagram.scss'
import SingleBox from '../componentParts/singleBox'
import {useEffect, useState} from 'react'
import Fade from 'react-reveal/Fade';
import PageFooter from '../componentParts/footer'
import TopNav from '../componentParts/topNav'
import { ArrowRightOutlined } from '@ant-design/icons';
import getWayaGram from '../states/waya/wayaGram';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import generalPageLoading from '../states/general';
import PageLoading from '../componentParts/pageLoading';
import { getSettings } from '../states/home';

function WayaGram(){


    const [WayaBankData, setWayaBankData] = useState({
        homeTopData: [],
        homeBenefitsData: [],
        homeBusinessData: [],
        homeDownloadData: [],
        homeIntegrateData: [],
        homeProductData: [],
        homeServicesData: [],
      })
      const data = useRecoilValueLoadable(getWayaGram)
      const [pageLoading, setPageLoading] = useRecoilState(generalPageLoading)
      const home = data.state === "hasValue" ? data.contents : {}
      const settingsApi = useRecoilValueLoadable(getSettings)
      const settings = settingsApi.state === "hasValue" ? settingsApi.contents : {}
      
      
      useEffect(() => {
        setWayaBankData({
          homeTopData: (home && home.bank) || [],
          homeBenefitsData: (home && home.benefit) || [],
          homeDownloadData: (home && home.download) || [],
          homeProductData: (home && home.products) || [],
        })
        setPageLoading( Array.isArray(home) || Object.keys(home).length ? false : true)
      }, [home])
    
      const {
        homeTopData,
        homeBenefitsData,
        homeProductData,
        homeDownloadData,
      } = WayaBankData

    const [gridData, setgridData] = useState([
        {
            title: 'make post',
            image: '/post.png',
            id: 'post',
            text: 'Make short posts and share across moments with friends and families'
        },
        {
            title: 'join group',
            image: '/Group.png',
            id:'group',
            text: 'Join group, follow events, like and follow personal and business pages'
        },
        {
            title: 'Socialize',
            image: '/socializeIcon.png',
            id:'socialize',
            text: 'Socialise with the power of a growing community on WayaGram, express yourself within the limits of boundless freedom'
        },
        {
            title: 'Vote',
            image: '/vote.png',
            id:'vote',
            text: 'Participate in poll on WayaGram, vote for friends and families with WayaGram'
        },
        {
            title: 'enjoy moments',
            image: '/smileys.png',
            id: 'moments',
            text: 'Watch short clips of your followers or any other WayaGram user. enjoy momemts, like and share with others in your timeline'
        },
        {
            title: 'advertise',
            id: 'advertise',
            image: '/marketing.png',
            text: 'Advertise and sell your product on the fastest growing social media platfrom growing from Nigeria and spreading throughout Africa'
        },
        {
            title: 'share live screen',
            image: '/live.png',
            id: 'live',
            text: 'Share live screen with anyone to follow and watch you on the move and as you host events. distance is not a barrier with the live screen feature of WayaGram. Share precious moments as they are happening live.'
        },
        {
            title: 'campaign',
            image: '/coms.png',
            id: 'campaign',
            text: 'Launch campaigns, anyone or entity can launch campaigns on WayaGram for personal, corporate, public and non-governmental events. WayaGram is the perfect tool for social momets'
        }
    ])

    const textList = [
        "Socialize",
        "Chats, voice and video​",
        "Organize life streaming events​",
        "Manage donation​",
        "Manage polling and voting​",
        "Promote your products and services",
        "Pay and transfer money instanlty as you socialize ​"
    ]
    return (     
       <div className='w-100'>
            {
                pageLoading ? (
                    <PageLoading />
                    ) : (
                    <div className='w-100'>
                        <TopNav/>
                        <div className='w-100 position-relative'>
                            <div>
                                <img alt='waya' src='/images/half-ball.png' className='position-absolute img-fluid top-0 start-0' />
                            </div>
                            <div className="position-relative home-jumbotron pt-5 ">
                                {
                                    homeTopData?.map((e, index)=> (
                                        (index+1) % 2 === 1 ? (
                                            <div key={e.id} className={`container-fluid w-100 pt-5 px-lg-5 d-${!e.status && "none"}`}>
                                                <div className="row">
                                                    <div className='col-md-6 pt-5'>
                                                        <Fade left className='w-100'>
                                                            <div>
                                                                <h1 className="fs-1 text-black my-lg-3" dangerouslySetInnerHTML={{__html: e.title || "The 1st and indeginuis Nigerian / African  social media  platform ​"}} />
                                                                <p className='fs-5 fw-bold pe-md-5' dangerouslySetInnerHTML={{__html: e.description || "With Wayagram, you’ll get fast, simple, secure messaging and calling for free, available on web apps & mobiles phones all over the world. You’ll also be able to manage donations, manage voting, manage events, manage store, promote your business etc.​"}} />
                                                                <div className='row py-4'>
                                                                    <div className='col-md-4'>
                                                                    <a href={e.google_url || settings?.play_store_link || ""}><img alt='waya' src='/images/play-store.png' className='w-100' /></a>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                    <a href={e.app_url || settings?.app_store_link || ""}><img alt='waya' src='/images/app-store.png' className='w-100' /></a>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                    {/* <a href={e.get_start_url} className='w-100 h-100 py-auto btn btn-orange bg-lg'>Get started</a> */}
                                                                    <a href={e.get_start_url || "/contact"} className='w-100 h-100 px-2 py-3 btn btn-orange bg-lg'><b>Get started</b></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                    </div>
                                                    <div className='col-md-6 text-center pt-5'>
                                                        <Fade right>
                                                            <div>
                                                                <img src={e.image_url || `/images/wayagram.png`} style={{width: "80%"}} className="m-0 p-0 mx-auto " alt='jumbotron' />
                                                            </div>
                                                        </Fade>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div key={e.id} className={`container-fluid w-100 pt-5 px-lg-5 d-${!e.status && "none"}`}>
                                                <div className="row">
                                                    <div className='col-md-6 text-center pt-5'>
                                                        <Fade right>
                                                            <div>
                                                                <img src={e.image_url || `/images/wayagram.png`} style={{width: "80%"}} className="m-0 p-0 mx-auto " alt='jumbotron' />
                                                            </div>
                                                        </Fade>
                                                    </div>
                                                    <div className='col-md-6 pt-5'>
                                                        <Fade left className='w-100'>
                                                            <div>
                                                                <h1 className="fs-1 text-black my-lg-3" dangerouslySetInnerHTML={{__html: e.title || "The 1st and indeginuis Nigerian / African  social media  platform ​"}} />
                                                                <p className='fs-5 fw-bold pe-md-5' dangerouslySetInnerHTML={{__html: e.description || "With Wayagram, you’ll get fast, simple, secure messaging and calling for free, available on web apps & mobiles phones all over the world. You’ll also be able to manage donations, manage voting, manage events, manage store, promote your business etc.​"}} />
                                                                <div className='row py-4'>
                                                                    <div className='col-md-4'>
                                                                    <a href={e.google_url || settings?.play_store_link || ""}><img alt='waya' src='/images/play-store.png' className='w-100' /></a>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                    <a href={e.app_url || settings?.app_store_link || ""}><img alt='waya' src='/images/app-store.png' className='w-100' /></a>
                                                                    </div>
                                                                    <div className='col-md-4'>                                                                   
                                                                     <a href={e.get_start_url || "/contact"} className='w-100 h-100 px-2 py-3 btn btn-orange bg-lg'><b>Get started</b></a>
                                                                    {/* <a href={e.get_start_url} className='w-100 h-100 py-auto btn btn-orange bg-lg'>Get started</a> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ))
                                }
                            </div>
                        </div>


                        <div className='w-100'>
                            <Fade bottom>
                            <div className='w-100'>
                                {
                                    homeBenefitsData?.map((e, index)=> (
                                        (index+1) % 2 === 1 ? (
                                            <div key={e.id} className={`bg- w-100 py-5 d-${!e.status && "none"}`}>
                                                <div className='row px-md-5'>
                                                    <div className='col-md-6 w-100'>
                                                    <div className='w-100 pt-5 text-center'>
                                                        <img src={e.image_url || `/images/products.png`} style={{width: "65%"}} className="mx-auto" alt='jumbotron' />
                                                    </div>
                                                    </div>
                                                    <div className='col-md-6 py-5'>
                                                    <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "Amazing Products"}} />
                                                    <div className='pe-lg-5 fs-6 pt-3'>
                                                        <ul style={{listStyleType: "none"}}>
                                                        {
                                                            e.listItem.map((val)=> (
                                                            <li key={val.id} className="py-2"><img alt='waya' src='/images/check.png' className=''/> <span className='ps-md-4' dangerouslySetInnerHTML={{__html: val.title}} /></li>
                                                            ))
                                                        }
                                                        </ul>
                                                    </div>
                                                    <a href={e.get_start_url || ""} className='btn btn-lg btn-orange-dark-gradient  px-4 mt-4 rounded'>Get Started Now! <ArrowRightOutlined /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div key={e.id} className={`bg- w-100 py-5 d-${!e.status && "none"}`}>
                                                <div className='row px-md-5'>
                                                    <div className='col-md-6 py-5'>
                                                    <p className='fs-1 fw-900p m-0 text-black pe-md-5 lh-sm' dangerouslySetInnerHTML={{__html: e.title || "Amazing Products"}} />
                                                    <div className='pe-lg-5 fs-6 pt-3'>
                                                        <ul style={{listStyleType: "none"}}>
                                                        {
                                                            e.listItem.map((val)=> (
                                                            <li key={val.id} className="py-2"><img alt='waya' src='/images/check.png' className=''/> <span className='ps-md-4' dangerouslySetInnerHTML={{__html: val.title}} /></li>
                                                            ))
                                                        }
                                                        </ul>
                                                    </div>
                                                    <a href={e.get_start_url || ""} className='btn btn-lg btn-orange-dark-gradient  px-4 mt-4 rounded'>Get Started Now! <ArrowRightOutlined /></a>
                                                    </div>
                                                    <div className='col-md-6 w-100'>
                                                    <div className='w-100 pt-5 text-center'>
                                                        <img src={e.image_url || `/images/products.png`} style={{width: "65%"}} className="mx-auto" alt='jumbotron' />
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ))
                                }
                            </div>
                            </Fade>
                        </div>



                        <div className='w-100'>
                            <Fade bottom cascade>
                                <div className='w-100' style={{backgroundColor:'#F9F8F7'}}>
                                    <div className='w-100 py-5 text-center'>
                                        <p className='fs-2 fw-bold text-black'>Product Features</p>
                                    </div>
                                    <div className='row pt-4 pb-4'>
                                    {
                                        homeProductData.length && homeProductData?.map(el => (
                                          <div key={el.id} className={`col-12 col-sm-6 col-md-4 d-${!el.status && "none"}`}>
                                            <div className=''>
                                              <SingleBox 
                                                  bgColor={'white'}
                                                  title={el.title}
                                                  body={el.description}
                                                  givenId={el.id}
                                                  imageName={el.image_url}
                                              />
                                            </div>
                                          </div>
                                    )
                                    )}           
                                    </div>
                                </div>
                        </Fade>
                        </div>

                                        

                        <div className='w-100'>
                            <Fade bottom>
                            <div className='w-00'>
                                {
                                    homeDownloadData?.map((e, index)=> (
                                        (index+1) % 2 === 1 ? (
                                                <div className={`w-100 py-5 d-${!e.status && "none"}`}>
                                                    <div className='row px-md-5 pt-md-5'>
                                                    <div className='col-md-6 w-100' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "80%", backgroundPosition: "center" }}>
                                                        <div className='w-100 text-center'>
                                                        <img src={e.image_url || `/images/holding-phone2.png`} style={{width: "100%"}} className="w-100 mx-auto" alt='jumbotron' />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 mt-2 py-5'>
                                                        <p className='fs-70p fw-780p pe-lg-5 text-black lh-sm mb-3' dangerouslySetInnerHTML={{__html: e.title || "Download the mobile app"}} />
                                                        <div className='pe-lg-5 fs-6 text-black'>
                                                        <span dangerouslySetInnerHTML={{__html: e.description || "It only takes a few minutes to start enjoying free benefits. Download Wayabank on Google Play or the App Store."}} />
                                                        </div>
                                                        <div className='row justify-content-between pe-lg-5 py-4'>
                                                        <div className='col-md-6'>
                                                            <a href={e.google_url || settings?.play_store_link || ""}><img alt='waya' src='/images/play-store.png' className='w-100' /></a>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <a href={e.app_url || settings?.app_store_link || ""}><img alt='waya' src='/images/app-store.png' className='w-100' /></a>
                                                        </div>
                                                        </div>
                                                        <a href={e.get_start_url} className='btn btn-lg btn-orange-dark-gradient mx-3  px-4 mt-3 rounded'>Get Started Wayapay Now! <ArrowRightOutlined /></a>
                                                    </div>
                                                    </div>
                                                </div>
                                        ) : (
                                            <div className={`w-100 py-5 d-${!e.status && "none"}`}>
                                                <div className='row px-md-5 pt-md-5'>
                                                    <div className='col-md-6 py-5'>
                                                        <p className='fs-70p fw-780p pe-lg-5 text-black lh-sm mb-3' dangerouslySetInnerHTML={{__html: e.title || "Download the mobile app"}} />
                                                        <div className='pe-lg-5 fs-6 text-black'>
                                                        <span dangerouslySetInnerHTML={{__html: e.description || "It only takes a few minutes to start enjoying free benefits. Download Wayabank on Google Play or the App Store."}} />
                                                        </div>
                                                        <div className='row justify-content-between pe-lg-5 py-4'>
                                                        <div className='col-md-6'>
                                                        <a href={e.google_url || settings?.play_store_link}><img alt='waya' src='/images/play-store.png' className='w-100' /></a>
                                                        </div>
                                                        <div className='col-md-6'>
                                                        <a href={e.app_url || settings.app_store_link}><img alt='waya' src='/images/app-store.png' className='w-100' /></a>
                                                        </div>
                                                        </div>
                                                        <a href={e.get_start_url || ""} className='btn btn-lg btn-orange-dark-gradient ml-3  px-4 mt-4 rounded'>Get Started Now! <ArrowRightOutlined /></a>
                                                    </div>
                                                    <div className='col-md-6 w-100' style={{ height: "100%", backgroundImage: 'url(/bg-ring.png)', backgroundRepeat: "no-repeat", backgroundSize: "80%", backgroundPosition: "center" }}>
                                                    <div className='w-100 text-center'>
                                                        <img src={e.image_url || `/images/holding-phone3.png`} style={{width: "90%"}} className="w-100 mx-auto" alt='jumbotron' />
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ))
                                }
                            </div>
                            </Fade>
                        </div>
                        <PageFooter/>
                    </div>
                )
            }
        </div>
    )
}

export default WayaGram
